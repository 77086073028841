import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSteam } from "../hooks";
import { useWallet } from "@solana/wallet-adapter-react";

export default function Steam() {
  const navigate = useNavigate();
  const location = useLocation();
  const { verifySteamAuth, createProfile } = useSteam();
  const wallet = useWallet();
  const [started, setStarted] = React.useState(false);

  React.useEffect(() => {
    if (started) return;
    if (!wallet.publicKey) return;
    const params = location.search;
    console.log("location", location.search);
    if (params) {
      setStarted(true);
      verifySteamAuth(params).then(
        ({
          data: {
            steamId,
            steamLevel,
            steamUsername,
            steamImage,
            steamCountry,
          },
        }: {
          data: {
            steamId: string;
            steamUsername: string;
            steamLevel: string;
            steamImage: string;
            steamCountry: string;
          };
        }) => {
          createProfile(
            steamId,
            steamLevel,
            steamUsername,
            steamImage,
            steamCountry
          );
          navigate(`/edit/socials`);
        }
      );
    }
  }, [
    location,
    started,
    navigate,
    verifySteamAuth,
    wallet.publicKey,
    createProfile,
  ]);

  return <></>;
}
