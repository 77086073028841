import { useWallet } from "@solana/wallet-adapter-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, metaplexActions } from "../store";
import { Metadata, Metaplex } from "@metaplex-foundation/js";
import { Connection } from "@solana/web3.js";
import { useSolpatrol } from "./useSolpatrol";
import { SolPatrolCollectible } from "../store/solpatrol";

export function useMetaplex() {
  const { publicKey } = useWallet();
  const dispatch = useDispatch();
  const metaplex = useSelector((state: RootState) => state.metaplex.metaplex);
  const ownedNfts = useSelector((state: RootState) => state.metaplex.ownedNfts);
  const { availableNftsCheckingJson } = useSolpatrol();
  const setMetaplex = React.useCallback(
    (connection: Connection) => {
      if (metaplex) return;
      const mx = Metaplex.make(connection);
      dispatch(metaplexActions.setMetaplex(mx));
    },
    [dispatch, metaplex]
  );

  const loadJson = React.useCallback(
    async (metadata: Metadata) => {
      let mt = { ...metadata };

      if (!metaplex) return;

      if (metadata.jsonLoaded && metadata.json) return mt;

      return metaplex
        .nfts()
        .load({
          metadata,
          loadJsonMetadata: true,
        })
        .then((nft) => {
          return nft;
        })
        .catch((e) => {
          console.log("errrrr", e);
          return mt;
        })
        .then((e) => {
          console.log("NFT", e);
          return e;
        });
      // console.log("mtttt", mt.json);
      // return mt;
    },
    [metaplex]
  );

  const fetchNfts = React.useCallback(() => {
    if (publicKey && metaplex) {
      metaplex
        .nfts()
        .findAllByOwner({
          owner: publicKey,
        })
        //@ts-ignore
        .then((x) => x.filter((y) => y.model === "metadata") as Metadata[])
        .then((x) => x.sort((a, b) => a.name.localeCompare(b.name)))
        .then(async (nfts) => {
          let nftss = await availableNftsCheckingJson([
            ...nfts,
          ] as SolPatrolCollectible[]);
          dispatch(
            metaplexActions.setOwnedNfts(
              nftss.filter((x) => x.jsonLoaded) as Metadata[]
            )
          );
        });
    }
  }, [availableNftsCheckingJson, dispatch, metaplex, publicKey]);

  return {
    fetchNfts,
    setMetaplex,
    ownedNfts,
    loadJson,
  };
}
