import React, { useEffect } from "react";
import Downshift from "downshift";
import { User } from "@honeycomb-protocol/edge-client";
import { Button, Image, Loading, Text } from "@nextui-org/react";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import { useHoneycomb } from "../../hooks";

export default function AutoComplete({ home }: { home?: boolean }) {
  const { fetchSearchedUser, wallet } = useHoneycomb();
  const [params, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = React.useState<string>("");
  const [searchedUsers, setSearchUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (params.get("username")) setSearchText(params.get("username") || "");
  }, [params]);

  useEffect(() => {
    const searchCurrentUser = async () => {
      if (searchText.length === 0 && wallet?.connected) {
        setLoading(true);
        const users = (await fetchSearchedUser({ wallet })).payload as User[];
        setSearchParams({ username: users[0]?.info.username });
        setSearchUsers(users);
        setLoading(false);
        return;
      }
    };
    searchCurrentUser();
  }, [searchText]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (searchText.length === 0) {
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const users = (await fetchSearchedUser({ name: searchText }))
          .payload as User[];
        setSearchParams({ username: searchText });
        setSearchUsers(users);
        setLoading(false);
        return;
      } catch (e) {
        setLoading(false);
        console.log(e);
        return;
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchText]);

  return (
    <Downshift
      // onChange={(selection) => {
      //   navigate({
      //     pathname: `/`,
      //     search: `?username=${selection.info.username}`,
      //   });
      // }}
      itemToString={(item) => (item ? item.username : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        getRootProps,
      }) => (
        <Box
          css={{
            position: "relative",
            "@xs": {
              maxWidth: "100%",
            },
            "@md": {
              maxWidth: "100%",
            },
            "@lg": {
              maxWidth: home ? "100%" : "80%",
            },
          }}
        >
          <Flex
            as="form"
            css={{
              background: "#121212",
              borderRadius: "70px",
              maxWidth: "100%",
              position: "relative",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "100%",
                height: "100%",
              }}
              {...getRootProps({}, { suppressRefError: true })}
            >
              <input
                placeholder="search for a friend by their username"
                style={{
                  width: "100%",
                  color: "white",
                  paddingLeft: 20,
                  minHeight: 60,
                  fontSize: 14,
                  borderRadius: 30,
                  backgroundColor: home ? "#1A1534" : "rgba(255,255,255,0.05)",
                  border: "1px solid transparent",
                }}
                {...getInputProps({
                  onChange: (e) => setSearchText(e.target.value),
                })}
              />
            </div>
            <Button
              type="submit"
              disabled={loading}
              css={{
                right: "20px",
                position: "absolute",
                background: "#2F2079",
                borderRadius: "60px",
                fontSize: "20px",
                fontWeight: "bold",
                py: "30px",
                pr: 0,
              }}
              iconLeftCss={{
                ml: "$7",
                svg: { height: "25px", width: "25px" },
              }}
              icon={<SearchIcon />}
            >
              {loading ? <Loading size="xs" /> : "Search"}
            </Button>
          </Flex>
          <Box
            as={"ul"}
            {...getMenuProps({
              style: {
                maxHeight: "200px",
                overflowY: "scroll",
                background: "#121212",
                padding: isOpen ? 20 : 0,
                borderRadius: 10,
                marginTop: -10,
              },
            })}
            css={{
              background: "#121212",
              borderRadius: "70px",
              position: "absolute",
              padding: "20px",
              zIndex: 2,

              "@xs": {
                width: "90%",
              },
              "@md": {
                width: "95%",
              },
              "@lg": {
                width: "95%",
              },
            }}
          >
            {isOpen ? (
              loading ? (
                <li
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  searching....
                </li>
              ) : !searchedUsers.length ? (
                <li
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  no user found
                </li>
              ) : (
                searchedUsers.map((item, index) => (
                  <li
                    key={index}
                    {...getItemProps({
                      key: item.info.username,
                      index,
                      item,
                      style: {
                        backgroundColor:
                          highlightedIndex === index
                            ? "rgba(255,255,255,0.05)"
                            : undefined,
                        cursor: "pointer",
                        width: "100%",
                        fontWeight: selectedItem === item ? "bold" : "normal",
                        height: 80,
                        display: "flex",
                        alignItems: "center",
                      },
                    })}
                  >
                    <Image
                      containerCss={{
                        width: 70,
                        height: 70,
                        marginRight: 10,
                      }}
                      alt="profile picture"
                      width={70}
                      height={70}
                      src={item.info.pfp}
                    />
                    <Box css={{ width: "85%" }}>
                      <Text css={{ fontSize: 12, fontWeight: "bold" }}>
                        {item.info.name}
                      </Text>
                      <Text css={{ fontSize: 12, fontWeight: "bold" }}>
                        {item.info.username}
                      </Text>
                      <Text css={{ fontSize: 12 }}>{item.info.bio}</Text>
                    </Box>
                  </li>
                ))
              )
            ) : null}
          </Box>
        </Box>
      )}
    </Downshift>
  );
}
