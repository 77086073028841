import React from "react";
import { Grid, Text } from "@nextui-org/react";

import { Box } from "../../ui/Box";
import SocialItem, { iSocial } from "./components/socialItem";
import { useHoneycomb, useTwitter } from "../../../hooks";

export interface ISocialsItem {
  name: iSocial;
  username?: string | string[];
  url?: string;
  press?: () => void;
}

const Socials = () => {
  const { AuthenticateTwitter } = useTwitter();
  const { user } = useHoneycomb();

  const socials: ISocialsItem[] = React.useMemo(() => {
    const twitterUsername = user?.socialInfo?.twitter?.split(",")[1];
    return [
      {
        name: "twitter",
        username: twitterUsername,
        url: twitterUsername && `https://twitter.com/${twitterUsername}`,
        press: async () => {
          await AuthenticateTwitter().catch((e) => {
            console.error(e);
          });
        },
      },
      // {
      //   name: "facebook",
      // },
      // {
      //   name: "instagram",
      // },
      // {
      //   name: "youtube",
      // },
      // {
      //   name: "twitch",
      // },
    ];
  }, [AuthenticateTwitter]);

  return (
    <Box css={{ p: 40, borderRadius: 20, width: "100%" }}>
      <>
        <Text h3>Socials</Text>
        <Box css={{ mt: 40, width: "100%" }}>
          <Grid.Container gap={1} direction={"column"}>
            {socials.map((e, i) => (
              <SocialItem item={e} key={i} />
            ))}
          </Grid.Container>
        </Box>
      </>
    </Box>
  );
};

export default Socials;
