import { Profile } from "@honeycomb-protocol/edge-client";
import { Metadata } from "@metaplex-foundation/js";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as web3 from "@solana/web3.js";

export type IRewards = {
  Bounty: number;
  Ammo: number;
  Food: number;
  Gems: number;
  "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1": number;
};

export type ISolPatrolStats = {
  averageRewardPerMission: IRewards;
  highestRewardsEarned: IRewards;
  missions: {
    [key: string]: number;
  };
  nfts: {
    [key: string]: IRewards;
  };
  totalMissionsCompleted: number;
  totalRewardsEarned: IRewards;
};

export type TokenAccountInfo = {
  tokenMint: web3.PublicKey;
  owner: string;
  state: "frozen" | string;
  tokenAmount: {
    amount: string;
    decimals: number;
    uiAmount: number;
    uiAmountString: string;
  };
};

export type SolPatrolCollectible = Metadata & TokenAccountInfo;

export type SolPatrolProfile = Profile & {
  walletAddress?: web3.PublicKey;
  stats?: ISolPatrolStats;
  collectibles?: SolPatrolCollectible[];
};

export interface SolpatrolState {
  profiles: { [key: string]: SolPatrolProfile };
  searchedProfiles: { [key: string]: SolPatrolProfile };
  loadings: { [key: string]: boolean | "fetched" | "failed" };
}

const initialState: SolpatrolState = {
  profiles: {},
  searchedProfiles: {},
  loadings: {},
};

export const slice = createSlice({
  name: "solpatrol",
  initialState,
  reducers: {
    setProfiles: (
      state,
      action: PayloadAction<{ [key: string]: SolPatrolProfile }>
    ) => {
      state.profiles = action.payload;
    },
    setSearchedProfiles: (
      state,
      action: PayloadAction<{ [key: string]: SolPatrolProfile }>
    ) => {
      state.searchedProfiles = action.payload;
    },
    setLoadings: (
      state,
      action: PayloadAction<{ [key: string]: boolean | "fetched" | "failed" }>
    ) => {
      state.loadings = { ...state.loadings, ...action.payload };
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
