import React, { useEffect } from "react";
// import { useLocation, useParams } from "react-router-dom";
// import { Profile } from "@honeycomb-protocol/edge-client";

// import { tabs } from "../Tab";
import { Box } from "../ui/Box";
// import { Collectibles } from "./Collectibles";
// import { Overview } from "./Overview";
// import { Stats } from "./Stats";
// import { useHoneycomb, useSolpatrol } from "../../hooks";

const Solpatrol = () => {
  // const { profiles: Profiles, searchedProfiles } = useSolpatrol();
  // const params = useParams();
  // const [activeTab, setActiveTab] = React.useState("Overview");
  // const { setSearchingUser, user, searchingUser } = useHoneycomb();
  // const [profile, setProfile] = React.useState<Profile | undefined>(
  //   undefined
  // );

  // const profiles = React.useMemo(() => {
  //   if (searchingUser) return searchedProfiles;
  //   return Profiles;
  // }, [searchingUser, Profiles, searchedProfiles]);

  // const location = useLocation();
  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const tab = query.get("tab");
  //   if (tab) {
  //     setActiveTab(tab);
  //   }
  // }, [location]);

  // // React.useEffect(() => {
  // //   if (params.username !== user?.username) {
  // //     setSearchingUser(params.username || false);
  // //   } else {
  // //     setSearchingUser(false);
  // //   }
  // // }, [params, setSearchingUser, user?.username]);

  // React.useEffect(() => {
  //   try {
  //     params.identity &&
  //       profiles[params.identity.toString()] &&
  //       setProfile(profiles[params.identity.toString()]);
  //   } catch {}
  // }, [setProfile, profile, params.identity, profiles]);

  // if (!profile) return <h1>Profile not loaded</h1>;

  // const render: any = () => {
  //   switch (activeTab.toLowerCase()) {
  //     case tabs[0].toLowerCase():
  //       return <Overview profile={profile} />;
  //     case tabs[1].toLowerCase():
  //       return <Stats profile={profile} />;
  //     case tabs[2].toLowerCase():
  //       return <Collectibles profile={profile} />;

  //     default:
  //       return <h1>No project match</h1>;
  //   }
  // };
  return <Box></Box>;
};

export default Solpatrol;
