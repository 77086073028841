import React, { ChangeEvent, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Input,
  Image,
  Text,
  Loading,
  FormElement,
} from "@nextui-org/react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useForm, useWatch } from "react-hook-form";
import { Metadata } from "@metaplex-foundation/js";
import { Modal } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import Cropper, { ReactCropperElement } from "react-cropper";

import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import imageLoadingSvg from "../../assets/image-loading.svg";
import imageLoadFailedSvg from "../../assets/image-load-failed.svg";
import { RootState } from "../../store";
import AutoComplete from "./AutoCompelete";
import { auth } from "../../store/actions";
import { useHoneycomb, useMetaplex, useSteam } from "../../hooks";
import { useSearchParams } from "react-router-dom";

export const NftPreview = ({
  nft,
  onClick,
  selected,
}: {
  nft: Metadata;
  onClick: React.MouseEventHandler<unknown>;
  selected: boolean;
}) => {
  const { loadJson } = useMetaplex();

  const [image, setImage] = React.useState<string>(
    nft.jsonLoaded ? nft.json?.image || imageLoadFailedSvg : imageLoadingSvg
  );
  const name = React.useMemo(() => nft.name || nft.symbol || "No name", [nft]);
  React.useEffect(() => {
    if (!nft.jsonLoaded)
      loadJson(nft).then((x) => setImage(x?.json?.image || imageLoadFailedSvg));
  }, [nft, loadJson]);
  return (
    <Image
      css={{
        width: 184,
        height: "100%",
        // border: selected ? "2px solid red" : "",
        background: "silver",
        cursor: "pointer",
        objectFit: "cover !important",
      }}
      containerCss={{
        background:
          "linear-gradient(91.47deg, #3EBA8D -10.34%, #6475B9 102.62%)",
        padding: selected ? 5 : 0,
      }}
      title={name}
      onClick={onClick}
      src={image}
    />
  );
};
export const AvatarWalletModal = ({
  pfpModal,
  pfp,
  closePfpModal,
  setSelectedPfpNft,
  setPfp,
}: {
  pfpModal: boolean;
  selectedPfpNft?: Metadata | null;
  pfp?: null | string | File;
  closePfpModal: () => void;
  setSelectedPfpNft: (nft: Metadata | null) => void;
  setPfp: (pfp: File | string) => void;
}) => {
  const { ownedNfts } = useMetaplex();
  const imgRef = React.useRef<FormElement | null>(null);
  const cropperRef = React.useRef<ReactCropperElement>(null);
  const preview = React.useMemo(() => {
    if (!pfp) return imageLoadFailedSvg;
    if (typeof pfp === "string") return pfp;
    return URL.createObjectURL(pfp);
  }, [pfp]);
  const nftsCards = React.useMemo(
    () => (
      <Grid.Container gap={2}>
        {ownedNfts.map((nft: any) => (
          <Grid key={nft.address.toString()} xs={6} sm={4}>
            <NftPreview
              nft={nft}
              selected={pfp === null ? false : nft.json?.image === pfp}
              onClick={() => {
                if (nft.json?.image) {
                  if (pfp === nft.json?.image) {
                    setSelectedPfpNft(null);
                    setPfp("");
                  } else {
                    setSelectedPfpNft(nft);
                    setPfp(nft.json?.image || "");
                  }
                  // closePfpModal();
                }
              }}
            />
          </Grid>
        ))}
      </Grid.Container>
    ),
    [ownedNfts, pfp, setSelectedPfpNft, setPfp]
  );

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <Modal
      closeButton
      // fullScreen
      width="800px"
      animated={false}
      aria-labelledby="modal-title"
      open={pfpModal}
      onClose={closePfpModal}
      // width="100%"
      css={{ padding: 0, background: "rgba(0,0,0,0.9)" }}
    >
      <Modal.Header>
        <Text id="modal-title" size={18}>
          Select your PFP
        </Text>
      </Modal.Header>
      <Modal.Body css={{ padding: 0 }}>
        <Grid.Container>
          <Grid
            sm={12}
            xs={12}
            justify={"center"}
            alignItems="center"
            direction="column"
          >
            <Box
              css={{
                width: 184,
                height: 184,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage:
                  "linear-gradient(91.47deg, #3EBA8D -10.34%, #6475B9 102.62%)",
              }}
            >
              <Box
                className={"preview-image"}
                onClick={() => imgRef.current?.click()}
                css={{
                  width: "95%",
                  height: "95%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "black",
                  cursor: "pointer",
                  position: "relative",
                  transition: "background 2s",
                }}
              >
                <Cropper
                  style={{ height: 400, width: "100%", display: "none" }}
                  src={preview}
                  checkOrientation={true}
                  // Cropper.js options
                  initialAspectRatio={16 / 9}
                  guides={false}
                  crop={onCrop}
                  ref={cropperRef}
                />
                {!pfp ? (
                  <Text css={{ fontWeight: "600", textAlign: "center" }}>
                    Upload Pfp... Or select from NFTs
                  </Text>
                ) : (
                  <Image width={"100%"} height={"100%"} src={preview} />
                )}
                <Input
                  type="file"
                  ref={(ref) => (imgRef.current = ref)}
                  css={{
                    position: "absolute",
                    // visibility: "hidden",
                    width: "100%",
                    opacity: 0,
                    height: "185px",
                  }}
                  placeholder="Upload Pfp... Or select from NFTs"
                  onChange={(e: ChangeEvent<FormElement>) => {
                    setSelectedPfpNft(null);
                    //@ts-ignore
                    setPfp(e.target.files[0]);
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid.Container>
        <Box css={{ marginTop: 20, padding: 20 }}>
          <Text
            h3
            style={{
              fontWeight: "600",
              letterSpacing: 0.5,
              textAlign: "center",
              fontSize: 30,
            }}
          >
            Your Avatars
          </Text>
          {nftsCards}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          auto
          light
          color={pfp ? "default" : "error"}
          onPress={closePfpModal}
          css={{ fontSize: 20 }}
        >
          {pfp ? "Done" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const ConnectWallet = () => {
  const dispatch = useDispatch();
  const [params, setSearchParams] = useSearchParams();

  const walletModal = useWalletModal();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { user, edgeClient, isUsernameAvailable, createUser, wallet } =
    useHoneycomb();
  const { register, control } = useForm();
  const { clearProfileData } = useSteam();
  const [usernameAvailable, setUsernameAvailable] = React.useState<
    boolean | null
  >(null);
  const [loadingAvailibility, setLoadingAvailibility] =
    React.useState<boolean>(false);
  const username = useWatch({ control, name: "username" });
  const name = useWatch({ control, name: "name" });
  const bio = useWatch({ control, name: "bio" });

  const [pfpModal, setPfpModal] = React.useState(false);
  const [selectedPfpNft, setSelectedPfpNft] = React.useState<Metadata | null>(
    null
  );
  const [pfp, setPfp] = React.useState<null | string | File>(null);
  const openPfpModal = () => setPfpModal(true);
  const closePfpModal = () => {
    setPfpModal(false);
  };

  React.useEffect(() => {
    if (!edgeClient) return;
    if (!username || !!!username?.length) return setUsernameAvailable(null);
    setLoadingAvailibility(true);

    setSearchParams({ username: "" });
    const timeout = setTimeout(async () => {
      const isAvailable = await isUsernameAvailable(username);
      setUsernameAvailable(isAvailable || false);
      setLoadingAvailibility(false);
    }, 2000);

    return () => {
      setLoadingAvailibility(false);
      clearTimeout(timeout);
    };
  }, [isUsernameAvailable, edgeClient, username, params]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(() => {
        return false;
      });
    }, 2000);
  }, [setLoading]);

  return (
    <Box css={{ position: "relative" }}>
      {/* PFP Selector Modal */}
      {loading ? (
        <Flex
          direction={"column"}
          css={{
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            as="img"
            src="/images/honeycomb-horizontal-logo.png"
            alt="hr-logo"
            css={{ maxW: "20%" }}
          />
          <Loading color="primary" textColor="primary" />
        </Flex>
      ) : (
        <>
          <Container>
            <Box
              css={{
                position: "absolute",
                right: 0,
                top: 0,
                maxW: "250px",
                zIndex: 0,
              }}
              as="img"
              src="/images/wallet-connect/triangle.png"
            />
            <Box
              css={{
                position: "absolute",
                left: 0,
                bottom: 0,
                maxW: "200px",
                zIndex: 0,
              }}
              as="img"
              src="/images/wallet-connect/qube.png"
            />
            <Grid.Container
              alignItems="center"
              css={{
                height: "100vh",
                zIndex: 1,
                "@sm": {
                  justifyContent: "center",
                  width: "100% !important",
                },
              }}
            >
              <Grid
                md={5}
                xs={12}
                css={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <Box
                  as="img"
                  src="/images/honeycomb-horizontal-logo.png"
                  alt="hr-logo"
                  css={{ maxW: "85%" }}
                />
              </Grid>
              <Grid
                md={7}
                xs={12}
                css={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                {wallet?.connected ? (
                  !user && (
                    <Box css={{ borderLeft: "2px solid $primary", p: "$20" }}>
                      <AvatarWalletModal
                        pfpModal={pfpModal}
                        closePfpModal={closePfpModal}
                        setPfp={setPfp}
                        pfp={pfp}
                        selectedPfpNft={selectedPfpNft}
                        setSelectedPfpNft={setSelectedPfpNft}
                      />

                      <Button
                        onPress={async() => {
                          clearProfileData();
                          await dispatch(auth.logout() as any);
                        }}
                        css={{
                          my: "$15",
                          padding: "35px 50px",
                          minWidth: "420px",
                          background:
                            "linear-gradient(91.47deg, #3EBA8D -10.34%, #6475B9 102.62%)",
                          borderRadius: "75px",
                          fontSize: "20px",
                          fontWeight: "bold",

                          color: "#000",
                        }}
                      >
                        Disconnect {wallet?.publicKey?.toString().slice(0, 4)}
                        ...
                      </Button>

                      <Flex
                        as="form"
                        onSubmit={async (e) => {
                          e.preventDefault();
                          await createUser({
                            username: username,
                            bio: bio,
                            pfp:
                              pfp ||
                              "https://arweave.net/mS4nhJfdk3bHetupRAKpDNa-LxwjDCnikiUydC_YXI4",
                            name: name,
                          });
                        }}
                        css={{
                          background: "#121212",
                          borderRadius: "70px",
                          position: "relative",
                          padding: "20px",
                        }}
                        direction="column"
                        style={{ gap: "10px" }}
                      >
                        <Button
                          css={{
                            background: "#2F2079",
                            borderRadius: "60px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            py: "30px",
                            pr: 0,
                          }}
                          onPress={openPfpModal}
                        >
                          {selectedPfpNft
                            ? selectedPfpNft.name
                            : pfp instanceof File
                            ? pfp.name
                            : "Select PFP"}
                        </Button>
                        <Box css={{ position: "relative" }}>
                          <Input
                            aria-label="username"
                            {...register("username", { required: true })}
                            css={{
                              width: "100%",
                              borderRadius: "60px",
                              overflow: "hidden",
                              border:
                                usernameAvailable === false
                                  ? "1px solid red"
                                  : "none",
                              input: {
                                py: "15px",
                                px: "$5",
                                pr: 50,
                              },
                              "&>div": { height: "inherit !important" },
                            }}
                            placeholder="Username"
                          />
                          {loadingAvailibility && (
                            <Loading
                              css={{
                                position: "absolute",
                                right: 30,
                                top: 20,
                              }}
                              size="xs"
                            />
                          )}
                          {usernameAvailable && !usernameAvailable && (
                            <Text css={{ color: "red", ml: 20 }}>
                              Username already used
                            </Text>
                          )}
                        </Box>
                        <Input
                          aria-label="name"
                          {...register("name", { required: true })}
                          css={{
                            width: "100%",
                            borderRadius: "60px",
                            overflow: "hidden",
                            input: {
                              py: "15px",
                              px: "$5",
                            },
                            "&>div": { height: "inherit !important" },
                          }}
                          placeholder="Name"
                        />
                        <Input
                          aria-label="bio"
                          {...register("bio", { required: true })}
                          css={{
                            width: "100%",
                            borderRadius: "60px",
                            overflow: "hidden",

                            input: {
                              py: "15px",
                              px: "$5",
                            },
                            "&>div": { height: "inherit !important" },
                          }}
                          placeholder="Bio"
                        />
                        <Button
                          type="submit"
                          disabled={
                            !usernameAvailable ||
                            loadingAvailibility ||
                            // !!!pfp ||
                            !!!username ||
                            !!!bio ||
                            !!!name
                          }
                          css={{
                            // right: "20px",
                            // position: "absolute",
                            background: "#2F2079",
                            borderRadius: "60px",
                            fontSize: "20px",
                            fontWeight: "bold",
                            py: "30px",
                            pr: 0,
                          }}
                        >
                          Create User
                        </Button>
                      </Flex>
                    </Box>
                  )
                ) : (
                  <Box
                    css={{
                      "@xs": {
                        borderLeft: "none",
                        width: "100%",
                      },
                      "@md": {
                        display: "block",
                        borderLeft: "2px solid $primary",
                        p: "$20",
                      },
                    }}
                  >
                    <Text
                      h2
                      css={{
                        fontWeight: 400,
                        fontSize: "58px",
                        lineHeight: "62px",
                        maxWidth: "80%",
                        "@xs": {
                          maxWidth: "100%",
                        },
                        "@md": {
                          maxWidth: "100%",
                        },
                        "@lg": {
                          maxWidth: "80%",
                        },
                        zIndex: 2,
                      }}
                    >
                      <b>All your games</b> in one place
                    </Text>
                    <Button
                      onPress={() => {
                        walletModal.setVisible(true);
                      }}
                      css={{
                        my: "$15",
                        padding: "35px 50px",
                        "@xs": {
                          minWidth: "100%",
                        },
                        "@md": {
                          minWidth: "100%",
                        },
                        "@lg": {
                          minWidth: "80%",
                        },
                        background:
                          "linear-gradient(91.47deg, #3EBA8D -10.34%, #6475B9 102.62%)",
                        borderRadius: "75px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#000",
                      }}
                    >
                      Connect Wallet {wallet?.connected}
                    </Button>
                    <Box>
                      <Text
                        css={{
                          fontSize: "18px",
                          mb: "$5",
                          mt: "$10",
                          ml: "$5",
                        }}
                      >
                        Or search for a friend
                      </Text>
                      <AutoComplete />
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid.Container>
          </Container>
        </>
      )}
    </Box>
  );
};

export default ConnectWallet;
