import { combineReducers, configureStore } from "@reduxjs/toolkit";
import honeycombReducer from "./honeycomb";
import authReducer from "./auth";
import metaplexReducer from "./metaplex";
import solpatrolReducer from "./solpatrol";
import steamReducer from "./steam";
import walletReducer from "./wallet";
import storage from "redux-persist/lib/storage";
import appStateSlice from "../redux/features/appStateSlice";
import tabStateSlice from "../redux/features/tabStateSlice";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import { AuthState, HoneycombState } from "./types";

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["auth"],
  },
  combineReducers({
    auth: authReducer,
    honeycomb: honeycombReducer,
    metaplex: metaplexReducer,
    solpatrol: solpatrolReducer,
    steam: steamReducer,
    wallet: walletReducer,
    appState: appStateSlice,
    tabState: tabStateSlice,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = {
  honeycomb: HoneycombState;
  auth: AuthState;
};
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export { actions as metaplexActions } from "./metaplex";
export { actions as solpatrolActions } from "./solpatrol";
export { actions as steamActions } from "./steam";
export { actions as walletActions } from "./wallet";
