import * as React from "react";
import { Text } from "@nextui-org/react";
import { User } from "@honeycomb-protocol/edge-client";
import { useParams, useSearchParams } from "react-router-dom";

import { Flex } from "../ui/Flex";
import { useHoneycomb } from "../../hooks";

const EmptyLayout = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { fetchSearchedUser } = useHoneycomb();
  const [searchedUser, setSearchedUser] = React.useState<User[] | undefined>(
    []
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const getUser = (
        await fetchSearchedUser({ name: params?.username || "" })
      ).payload;
      setSearchedUser(getUser as User[]);
      setLoading(false);
    };
    fetchUser();
  }, []);

  return (
    <Flex align={"center"}>
      {loading && !searchParams.get("wallets") ? (
        <Text h3>
          {searchedUser && searchedUser.length > 0
            ? searchedUser[0]?.info.username
            : ""}{" "}
          doesn't have connected platforms
        </Text>
      ) : (
        <Text h1 className="text-center">
          Kindly {searchParams.get("wallets") ? "select" : "connect"} Platform
        </Text>
      )}
    </Flex>
  );
};
export default EmptyLayout;
