import React from "react";
import { useHoneycomb } from "./useHoneycomb";
import { RootState, steamActions } from "../store";
import { useDispatch, useSelector } from "react-redux";
import * as web3 from "@solana/web3.js";
import { toast } from "react-toastify";

import {
  production,
  //  projects
} from "../config";
import { SteamProfile } from "../store/steam";
import { ICollectible } from "../components/Steam/Collectibles";
import { ISteamGame } from "../components/Steam/Overview";
import { IFriendList } from "../components/Steam/FriendList";

export function useSteam() {
  const dispatch = useDispatch();
  const profiles = useSelector((state: RootState) => state.steam.profiles);
  const searchedProfiles = useSelector(
    (state: RootState) => state.steam.searchedProfiles
  );
  const progress = useSelector((state: RootState) => state.steam.progress);

  const totalProfilesFromApi = useSelector(
    (state: RootState) => state.steam.totalProfilesFromApi
  );
  const searchedTotalProfilesFromApi = useSelector(
    (state: RootState) => state.steam.searchedTotalProfilesFromApi
  );
  const { honeycomb, user, authToken, searchedUser, searchingUser } =
    useHoneycomb();

  const api_url = React.useMemo(
    () =>
      production
        ? "https://mainnet.driver.steam.honeycombprotocol.com"
        : "http://localhost:4001",
    []
  );

  const setIsFetching = React.useCallback(
    (fetching: boolean, isSearched: boolean = false) => {
      if (isSearched) {
        dispatch(steamActions.setIsFetchingSearched(fetching));
      } else {
        dispatch(steamActions.setIsFetching(fetching));
      }
    },
    [dispatch]
  );

  const setProgress = React.useCallback(
    (progress: { [key: string]: boolean }) => {
      dispatch(steamActions.setProgress(progress));
    },
    [dispatch]
  );

  const PROJECT = React.useMemo(() => {
    if (!honeycomb?.cluster) return null;
    // return projects[honeycomb.cluster === "custom" ? "mainnet-beta" : honeycomb.cluster].steam;
  }, [honeycomb?.cluster]);

  const fetchingOwnedGames = React.useCallback(
    async (profile: SteamProfile, address: string) => {
      if (!honeycomb) return;
      const x = await honeycomb
        .http()
        .request(
          `${api_url}/steam/games/${address}/${
            profile.steamId || profile.profileIdentity
          }`,
          {
            method: "GET",
            withCredentials: true,
            // authToken: authToken || "",
          }
        );
      return x;
    },
    [api_url, honeycomb]
  );

  const fetchingFriends = React.useCallback(
    async (profile: SteamProfile, address: string) => {
      if (!honeycomb) return;
      const x = await honeycomb
        .http()
        .request(
          `${api_url}/steam/friends/${address}/${
            profile.steamId || profile.profileIdentity
          }`,
          {
            method: "GET",
            withCredentials: true,
            // authToken: authToken,
          }
        );
      return x;
    },
    [api_url, honeycomb]
  );

  const fetchingCollectibles = React.useCallback(
    async (profile: SteamProfile, address: string) => {
      if (!honeycomb) return;
      const x = await honeycomb
        .http()
        .request(
          `${api_url}/steam/games/collectibles/${address}/${
            profile.steamId || profile.profileIdentity
          }`,
          {
            method: "GET",
            withCredentials: true,
          }
        );
      return x;
    },
    [api_url, honeycomb]
  );

  const clearProfileData = React.useCallback(() => {
    dispatch(steamActions.setProfiles({}));
  }, [dispatch]);

  const authenticateSteam = React.useCallback(async () => {
    if (!honeycomb || !authToken) return;
    if (!honeycomb.project) throw new Error("No project selected");
    return honeycomb
      .http()
      .request(`${api_url}/steam/auth`, {
        method: "GET",
        withCredentials: true,
        authToken,
      })
      .then((x) => {
        if (x.steamUrl) {
          window.location.replace(x.steamUrl);
        }
      });
  }, [honeycomb, api_url, authToken]);

  const verifySteamAuth = React.useCallback(
    async (params: string) => {
      if (!honeycomb || !authToken) return;
      return honeycomb
        .http()
        .request(`${api_url}/steam/auth/callback${params}`, {
          method: "GET",
          authToken,
          withCredentials: true,
        })
        .then((p) => {
          return p;
        });
    },
    [honeycomb, api_url, authToken]
  );

  const profile = React.useCallback(
    async (
      steamId: string,
      steam: {
        steamLevel: number;
        steamUsername: string;
        steamImage: string;
        steamCountry: string;
      }
    ) => {
      if (!steamId) return;
      if (progress[steamId]) return profiles[steamId];
      let profile = profiles[steamId];
      if (profile) return profile;
      setProgress({ ...progress, [steamId]: true });
      if (!honeycomb || !PROJECT || !user) return;
      profile = (await honeycomb
        .identity()
        .fetch()
        .profile(PROJECT, user.address, steamId)
        .catch(() => {
          setProgress({ ...progress, [steamId]: false });
          return false;
        })) as SteamProfile;
      if (!profile) {
        setProgress({ ...progress, [steamId]: false });
        return false;
      }
      try {
        let {
          data: { games, totalValueOfInventory },
        }: {
          data: {
            games: ICollectible[];
            totalValueOfInventory: number;
          };
          success: boolean;
        } = await fetchingCollectibles(
          { ...profile, steamId } as SteamProfile,
          user.address.toString()
        );

        let {
          data: {
            games: games2,
            averagePlayTimePerGame,
            numberOfGamesPlayed,
            totalPlayTime,
          },
        }: {
          data: {
            games: ISteamGame[];
            averagePlayTimePerGame?: string;
            numberOfGamesPlayed?: number;
            totalPlayTime?: string;
          };
          success: boolean;
        } = await fetchingOwnedGames(
          { ...profile, steamId } as SteamProfile,
          user.address.toString()
        );
        let {
          data: { friends },
        }: {
          data: {
            friends: IFriendList[];
          };
          success: boolean;
        } = await fetchingFriends(
          { ...profile, steamId } as SteamProfile,
          user.address.toString()
        );
        dispatch(
          steamActions.setProfiles({
            [steamId]: {
              ...profile,
              collectibles: games,
              totalValueOfInventory: totalValueOfInventory || 0,
              games: games2 || [],
              averagePlayTimePerGame: averagePlayTimePerGame || "0",
              numberOfGamesPlayed: numberOfGamesPlayed || 0,
              totalPlayTime: totalPlayTime || "0",
              friends: friends || [],
              ...steam,
            } as SteamProfile,
          })
        );
        return true;
      } catch (e) {
        setProgress({ ...progress, [steamId]: false });
        return false;
      }
    },
    [
      progress,
      profiles,
      setProgress,
      honeycomb,
      PROJECT,
      user,
      fetchingCollectibles,
      fetchingOwnedGames,
      fetchingFriends,
      dispatch,
    ]
  );

  const searchedProfile = async (
    steamId: string,
    steam: {
      steamLevel: number;
      steamUsername: string;
      steamImage: string;
      steamCountry: string;
    }
  ) => {
    if (!steamId || !searchingUser) return;
    if (progress[steamId]) return searchedProfiles[steamId];
    let profile = searchedProfiles[steamId];
    if (profile) return profile;

    setProgress({ ...progress, [steamId]: true });
    if (!honeycomb || !PROJECT || !searchedUser) return;
    profile = (await honeycomb
      .identity()
      .fetch()
      .profile(PROJECT, new web3.PublicKey(searchedUser.address), steamId)
      .catch((e) => {
        console.log("error", e);
        setProgress({ ...progress, [steamId]: false });
        return undefined;
      })) as SteamProfile;
    if (!profile) {
      setProgress({ ...progress, [steamId]: false });
      return;
    }
    try {
      let {
        data: { games, totalValueOfInventory },
      }: {
        data: {
          games: ICollectible[];
          totalValueOfInventory: number;
        };
        success: boolean;
      } = await fetchingCollectibles(
        { ...profile, steamId } as SteamProfile,
        searchedUser.address.toString()
      );

      let {
        data: {
          games: games2,
          averagePlayTimePerGame,
          numberOfGamesPlayed,
          totalPlayTime,
        },
      }: {
        data: {
          games: ISteamGame[];
          averagePlayTimePerGame?: string;
          numberOfGamesPlayed?: number;
          totalPlayTime?: string;
        };
        success: boolean;
      } = await fetchingOwnedGames(
        { ...profile, steamId } as SteamProfile,
        searchedUser.address.toString()
      );
      let {
        data: { friends },
      }: {
        data: {
          friends: IFriendList[];
        };
        success: boolean;
      } = await fetchingFriends(
        { ...profile, steamId } as SteamProfile,
        searchedUser.address.toString()
      );

      dispatch(
        steamActions.setSearchedProfiles({
          [steamId]: {
            ...profile,
            collectibles: games,
            totalValueOfInventory: totalValueOfInventory || 0,
            games: games2 || [],
            averagePlayTimePerGame: averagePlayTimePerGame || "0",
            numberOfGamesPlayed: numberOfGamesPlayed || 0,
            totalPlayTime: totalPlayTime || "0",
            friends: friends || [],
            ...steam,
          } as SteamProfile,
        })
      );
      return true;
    } catch (e) {
      console.log("errorrrr", e);
      setProgress({ ...progress, [steamId]: false });
      return false;
    }
  };

  const fetchProfile = React.useCallback(
    async (forceRefresh = false) => {
      if (!!totalProfilesFromApi.length && !forceRefresh) return;
      if (!honeycomb || !authToken) return;
      if (user) {
        try {
          let {
            data,
          }: {
            data: {
              profiles: {
                address: string;
                identity: string;
                steamId: string;
                steamLevel: number;
                steamUsername: string;
                userAddress: string;
                steamImage: string;
                steamCountry: string;
              }[];
            };
          } = await honeycomb
            .http()
            .request(
              `${api_url}/steam/auth/profile/${user.address.toString()}`,
              {
                method: "GET",
                withCredentials: true,
                authToken,
              }
            );
          const { profiles } = data;
          dispatch(steamActions.setApiCalled(true));
          dispatch(steamActions.setApiProfiles(profiles));
        } catch (_e) {
          // console.error("verify steam profile", e);
        }
      }
    },
    [totalProfilesFromApi, honeycomb, authToken, user, api_url, dispatch]
  );
  const fetchSearchedProfile = React.useCallback(
    async (forceRefresh = false) => {
      if (
        (!!searchedTotalProfilesFromApi.length && !forceRefresh) ||
        !searchingUser
      )
        return;
      if (!honeycomb) return;
      if (searchedUser) {
        try {
          let {
            data,
          }: {
            data: {
              profiles: {
                address: string;
                identity: string;
                steamId: string;
                steamLevel: number;
                steamUsername: string;
                userAddress: string;
                steamImage: string;
                steamCountry: string;
              }[];
            };
          } = await honeycomb
            .http()
            .request(
              `${api_url}/steam/auth/profile/${searchedUser.address.toString()}`,
              {
                method: "GET",
                withCredentials: true,
              }
            );
          const { profiles } = data;
          dispatch(steamActions.setSearchedApiCalled(true));
          dispatch(steamActions.setSearchedApiProfiles(profiles));
        } catch (_e) {
          // console.error("verify steam profile", e);
        }
      }
    },
    [
      searchedTotalProfilesFromApi,
      honeycomb,
      searchedUser,
      api_url,
      dispatch,
      searchingUser,
    ]
  );

  const testing = React.useCallback(async () => {
    if (!honeycomb || !authToken || !PROJECT || !user) return;
    // let identity = (Math.random() * 9999 + 9999).toFixed(0).toString();
    const identity = "76561198067181071";
    honeycomb
      .identity()
      .fetch()
      .profile(PROJECT, user.address, identity)
      .catch((_) => {
        honeycomb
          .identity()
          .create()
          .profile(PROJECT, identity)
          .then(async (_) => {
            await honeycomb
              .http()
              .request(`${api_url}/steam/auth/createProfile`, {
                method: "POST",
                withCredentials: true,
                authToken,
                data: {
                  steamId: identity,
                  steamLevel: "6",
                  steamUsername: "dummy",
                  steamCountry: "US",
                  steamImage: "image",
                },
              })
              .catch((error) => new Error(error));
            fetchProfile();
          })
          .catch((err) => {
            console.log("creatingProfileError", err);
          });
      });
  }, [PROJECT, api_url, authToken, fetchProfile, honeycomb, user]);

  const createProfile = React.useCallback(
    async (
      steamId: string,
      steamLevel: string,
      steamUsername: string,
      steamImage: string,
      steamCountry: string
    ) => {
      if (!honeycomb || !authToken || !PROJECT || !user) return;
      const toastId = toast.loading("Fetching latest profile info...", {
        progress: 0.5,
      });
      await honeycomb
        .identity()
        .fetch()
        .profile(PROJECT, user.address, steamId)
        .then(async () => {
          await honeycomb
            .http()
            .request(`${api_url}/steam/auth/createProfile`, {
              method: "POST",
              withCredentials: true,
              authToken,
              data: {
                steamId,
                steamLevel,
                steamUsername,
                steamImage,
                steamCountry,
              },
            })
            .then(() => {
              fetchProfile(true);
            })
            .catch(() => {
              toast.update(toastId, {
                type: "error",
                render: "Failed to create profile.",
                progress: 1,
                autoClose: 5000,
              });
              toast.error("Failed to create profile.");
            });

          toast.update(toastId, {
            type: "success",
            render: "Fetched profile, Your steam record will be updated soon.",
            progress: 1,
            autoClose: 5000,
          });
          toast.success(
            "Fetched profile, Your steam record will be updated soon."
          );
        })
        .catch(async () => {
          toast.update(toastId, {
            render: "Creating Profile....",
          });
          setTimeout(() => {
            toast.update(toastId, {
              render: "Fetching data from steam...",
              progress: 0.75,
            });
          }, 3000);

          await honeycomb
            .identity()
            .create()
            .profile(PROJECT, steamId, { skipPreflight: true })
            .then(async () => {
              await honeycomb
                .http()
                .request(`${api_url}/steam/auth/createProfile`, {
                  method: "POST",
                  withCredentials: true,
                  authToken,
                  data: {
                    steamId,
                    steamLevel,
                    steamUsername,
                    steamImage,
                    steamCountry,
                  },
                })
                .catch((error) => new Error(error));
              fetchProfile();
              toast.update(toastId, {
                type: "success",
                render:
                  "Created profile, Your steam record will be updated soon.",
                progress: 1,
                autoClose: 5000,
              });
              toast.success(
                "Created profile, Your steam record will be updated soon."
              );
            })
            .catch((_error) => {
              toast.update(toastId, {
                type: "error",
                render: "Failed to create profile.",
                progress: 1,
                autoClose: 5000,
              });
              toast.error("Failed to create profile.");
            });
        })
        .catch(() => {
          toast.update(toastId, {
            type: "error",
            render: "Failed to create profile.",
            progress: 1,
            autoClose: 5000,
          });
          toast.error("Failed to create profile.");
          // console.error(e);
        });
    },
    [PROJECT, api_url, authToken, fetchProfile, honeycomb, user]
  );

  const clearSearchedData = React.useCallback(() => {
    dispatch(steamActions.setProgress({}));
    dispatch(steamActions.setSearchedApiCalled(false));
    dispatch(steamActions.setSearchedApiProfiles([]));
    dispatch(steamActions.setSearchedProfiles({}));
  }, [dispatch]);

  return {
    profiles,
    profile,
    fetchingOwnedGames,
    fetchingFriends,
    clearProfileData,
    fetchingCollectibles,
    authenticateSteam,
    verifySteamAuth,
    createProfile,
    fetchProfile,
    testing,
    searchedProfile,
    fetchSearchedProfile,
    clearSearchedData,
    searchedProfiles,
    setIsFetching,
    PROJECT,
  };
}
