import { Button, Text } from "@nextui-org/react";
import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const tabs = ["Overview", "Stats", "Collectibles"];
export const steamTabs = ["Overview", "Stats", "Collectibles", "FriendList"];
const NewTab = (props: { module: string }) => {
  const [activeTab, setActiveTab] = useState("Overview");
  const params = useParams();
  const profiles = useSelector((state: RootState) => state.steam.profiles);
  const location = useLocation();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const tabsM = useMemo(() => {
    if (props.module === "steam" && params.steamId) {
      const profile = profiles[params.steamId];
      return steamTabs.filter((tab) => {
        return !(
          (tab === "Collectibles" &&
            profile &&
            !profile.collectibles?.length) ||
          (tab === "FriendList" && profile && !profile.friends?.length)
        );
      });
    } else return tabs;
  }, [props.module, params.steamId, profiles]);

  return (
    <Box css={{ mb: "$15" }}>
      <Flex direction={"row"} css={{ gap: "$5" }}>
        {tabsM.map((tab, index) => (
          <Text
            key={index}
            as={Button}
            onClick={() => {
              navigate(`${location.pathname}?tab=${tab}`);
              setSearchParams({
                tab: tab,
              });
            }}
            css={{
              minWidth: "20px",
              color: tab === activeTab ? "#C39CFC" : "#fff",
              background: "none",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "41px",
              px: "0 !important",
              mx: "$5",
              borderRadius: "0",
              py: "$8",
              borderBottom: "1px solid",
              borderColor:
                tab.toLowerCase() === activeTab.toLowerCase()
                  ? "#C39CFC"
                  : "transparent",
            }}
          >
            {tab}
          </Text>
        ))}
      </Flex>
    </Box>
  );
};

export default NewTab;
