import { Grid, Image, Progress, Text } from "@nextui-org/react";
import { Box } from "../../ui/Box";
import { Flex } from "../../ui/Flex";
import moment from "moment";
import { SteamProfile } from "../../../contexts/SteamProvider";
import { useHoneycomb } from "../../../hooks";

export interface ISteamGame {
  app_id: number;
  createdAt: string;
  gameImage: string;
  gameName: string;
  index: number;
  achievements: { achieved: number }[];
  stats: { name: string; value: number }[];
  playTimeLinuxForever: number;
  playTimeMacForever: number;
  playTimeWindowsForever: number;
  playtimeForever: number;
  rTimeLastPlayed: number;
  steamGame: number;
  updatedAt: string;
  _id: number;
}

export const Overview = ({
  profile,
}: {
  profile: SteamProfile | undefined;
}) => {
  const percentage = (partialValue: number, totalValue: number) =>
    (100 * partialValue) / totalValue;
  const { user } = useHoneycomb();

  return (
    <Box>
      <Flex
        css={{
          position: "relative",
          zIndex: 2,
          py: "$5",
          px: "$10",
        }}
        justify="between"
        align="center"
      >
        <Flex align="center" css={{ textAlign: "center", gap: "$10" }}>
          <Box className={"hexagonn1"}>
            <Box className={"hexagonn-inner"}>
              <Box className="hexagonn-box">
                {/* <Box
                  className="hexagonn-img"
                  css={{
                    maxWidth: "100%",
                  }}
                  as="img"
                  src={profile?.steamImage || user?.data?.pfp}
                /> */}
              </Box>
            </Box>
          </Box>
          <Box>
            <Text css={{ fontSize: "22px", fontWeight: 600 }}>
              {profile?.steamUsername || "username"}
            </Text>
            <Text>{profile?.steamCountry}</Text>

            <Box
              onClick={() => {
                window.open(
                  `https://steamcommunity.com/profiles/${profile?.steamId}`,
                  "_blank"
                );
              }}
              css={{ display: "flex", cursor: "pointer" }}
            >
              <Image
                css={{ width: 20 }}
                src={require("../../../assets/images/steam.png")}
              />
              <Text css={{ ml: 5, color: "#5062AB" }}>
                @{profile?.steamUsername}
              </Text>
            </Box>
          </Box>
        </Flex>

        <Flex align="center" css={{ gap: "$8" }}>
          <Text css={{ fontSize: "25px", fontWeight: 500 }}>Level</Text>
          <Text
            css={{
              fontWeight: 500,
              fontSize: "21px",
              background: "rgba(255, 255, 255, 0.05)",
              border: "2px solid #5062AB",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              lineHeight: "45px",
              textAlign: "center",
            }}
          >
            {profile?.steamLevel || 0}
          </Text>
        </Flex>
      </Flex>

      {/* Recently Played */}
      {!!profile?.games?.length &&
        !!profile.games.filter((a) => a.rTimeLastPlayed).length && (
          <Flex
            justify="between"
            align="center"
            css={{
              mt: "$15",
              background: "rgba(0, 0, 0, 0.15)",
              borderRadius: "15px",
              w: "100%",
              px: "$10",
              py: "$10",
            }}
          >
            <Text css={{ fontWeight: 500, fontSize: "19px" }}>
              Recently Played
            </Text>
            <Text css={{ fontWeight: 500, fontSize: "19px" }}>
              {/* 65.72Hours Past 2 Weeks */}

              {moment(
                ([...profile.games]
                  ?.sort((a, b) => a.rTimeLastPlayed - b.rTimeLastPlayed)
                  ?.shift()?.rTimeLastPlayed || 0) * 1000
              ).fromNow()}
            </Text>
          </Flex>
        )}

      {/* top games */}
      <Box css={{ mt: "$15" }}>
        <Text css={{ fontWeight: 600, fontSize: "22px", my: "$5" }}>
          Your Top Games
        </Text>

        {!!profile?.games?.length &&
          [...profile?.games]
            ?.sort((a, b) => b.playtimeForever - a.playtimeForever)
            ?.slice(0, 2)
            .map((item, index) => (
              <Box
                key={index}
                css={{
                  mt: "$15",
                  background: "rgba(0, 0, 0, 0.15)",
                  borderRadius: "15px",
                  w: "100%",
                  px: "$10",
                  py: "$10",
                }}
              >
                <Grid.Container gap={3}>
                  <Grid lg={5}>
                    <Box
                      as="img"
                      src={`https://cdn.akamai.steamstatic.com/steam/apps/${item.app_id}/header.jpg`}
                      css={{ borderRadius: "20px", width: "100%" }}
                    />
                  </Grid>
                  <Grid lg={7}>
                    <Box css={{ width: "100%" }}>
                      <Text
                        css={{
                          fontWeight: 600,
                          fontSize: "24px",
                          lineHeight: "34px",
                          mb: "$10",
                        }}
                      >
                        {item.gameName}
                      </Text>
                      <Flex css={{ gap: "$12" }}>
                        <Box
                          css={{
                            background: "rgba(0, 0, 0, 0.2)",
                            width: "100%",
                            textAlign: "center",
                            borderRadius: "15px",
                            p: "$15",
                          }}
                        >
                          <Text css={{ fontSize: "20px", fontWeight: 500 }}>
                            Total Playtime
                          </Text>
                          <Text
                            css={{
                              fontSize: "20px",
                              fontWeight: 400,
                              opacity: ".3",
                            }}
                          >
                            {(item.playtimeForever / 60).toFixed()} hours
                          </Text>
                        </Box>
                        <Box
                          css={{
                            background: "rgba(0, 0, 0, 0.2)",
                            width: "100%",
                            textAlign: "center",
                            borderRadius: "15px",
                            p: "$15",
                          }}
                        >
                          <Text css={{ fontSize: "20px", fontWeight: 500 }}>
                            Last Played
                          </Text>
                          <Text
                            css={{
                              fontSize: "20px",
                              fontWeight: 400,
                              opacity: ".3",
                            }}
                          >
                            {moment(item.rTimeLastPlayed * 1000).format(
                              "DD MMMM"
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Grid>
                </Grid.Container>

                {!!item.achievements.length && (
                  <Box css={{ mt: "$10" }}>
                    <Text
                      css={{
                        fontWeight: 600,
                        fontSize: "22px",
                        lineHeight: "34px",
                        mb: "$5",
                      }}
                    >
                      Achievement Progress
                    </Text>

                    <Box css={{ position: "relative" }}>
                      <Progress
                        css={{
                          "& .nextui-progress-wrapper-enter-active": {
                            background: "#6475B9",
                            borderRadius: "5px",
                          },
                          background: "none",
                          border: "1px solid #6475B9",
                          height: "40px",
                          borderRadius: "10px",
                        }}
                        value={percentage(
                          item.achievements.filter((e) => e.achieved === 1)
                            .length,
                          item.achievements.length
                        )}
                      />
                      <Text
                        css={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          fontWeight: 400,
                          fontSize: "20px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        {
                          item.achievements.filter((e) => e.achieved === 1)
                            .length
                        }
                        /{item.achievements.length}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
      </Box>
    </Box>
  );
};
