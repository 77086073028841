import { PublicKey } from "@metaplex-foundation/js";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface WalletState {
  publicKey: PublicKey | null;
  connected: boolean;
}

const initialState: WalletState = {
  publicKey: null,
  connected: false,
};

export const slice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setPublicKey: (state, action: PayloadAction<PublicKey | null>) => {
      state.publicKey = action.payload;
    },
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
