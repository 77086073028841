import React from "react";
import { useForm } from "react-hook-form";
import { Text, Grid, Input, Textarea, Image, Button } from "@nextui-org/react";

import { Box } from "../../ui/Box";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { AvatarWalletModal } from "../../ConnectWallet";
import { useHoneycomb } from "../../../hooks";

const Personal = () => {
  const { user, updateUser } = useHoneycomb();
  const { register, handleSubmit } = useForm();

  const [pfpModal, setPfpModal] = React.useState<boolean>(false);
  const [pfp, setPfp] = React.useState<undefined | string | File>(
    user?.info?.pfp
  );

  const closePfpModal = () => {
    setPfpModal(false);
  };

  const handleEdit = React.useCallback(
    async (data?: { username: string; name: string; bio: string } | any) => {
      await updateUser({ name: data.name, bio: data.bio, pfp });
    },
    [pfp, updateUser]
  );

  return (
    <Box css={{ p: 40, borderRadius: 20, width: "100%" }}>
      <AvatarWalletModal
        pfpModal={pfpModal}
        closePfpModal={closePfpModal}
        setSelectedPfpNft={() => {}}
        setPfp={setPfp}
        pfp={pfp}
      />
      <Text h3>Personal Information</Text>
      <Grid.Container
        as="form"
        gap={2}
        direction={"column"}
        onSubmit={handleSubmit(handleEdit)}
      >
        <Grid xs={6} direction="column">
          <Text h3>Name</Text>
          <Input
            placeholder={user?.info?.name}
            aria-label="name"
            {...register("name", { required: false })}
          />
        </Grid>
        <Grid xs={8} direction="column">
          <Text h3>Bio</Text>
          <Textarea
            fullWidth
            placeholder={user?.info?.bio || "Enter your amazing ideas."}
            rows={8}
            aria-label="bio"
            {...register("bio", { required: false })}
          />
        </Grid>
        <Grid xs={8} direction="column">
          <Text h3>Avatar</Text>
          <Grid.Container gap={4} alignItems="flex-end">
            <Grid>
              <Image
                css={{
                  height: 180,
                  width: 180,
                }}
                src={
                  typeof pfp === "string"
                    ? pfp
                    : pfp instanceof File
                    ? URL.createObjectURL(pfp)
                    : ""
                }
              />
            </Grid>
            <Grid>
              <Button
                iconLeftCss={{
                  color: "white",
                  fontSize: 20,
                  width: 50,
                  position: "relative",
                  mt: -20,
                  ml: -30,
                  mr: 10,
                }}
                icon={<BsFillCloudUploadFill />}
                onClick={() => setPfpModal(true)}
                css={{
                  backgroundImage:
                    "linear-gradient(87.03deg, #3EBA8D 14.58%, #6475B9 114.7%)",
                }}
              >
                Change
              </Button>
            </Grid>
          </Grid.Container>
        </Grid>
        {/* <Grid xs={8} direction="column">
          <Text h3>Profile Background</Text>
          <Grid.Container
            gap={4}
            css={{ background: "rgba(0, 0, 0, 0.2)", marginTop: 10 }}
          >
            <Grid>
              <Image
                css={{ width: 160, height: 220, background: "red" }}
                src=""
              />
            </Grid>
            <Grid>
              <Image
                css={{ width: 160, height: 220, background: "red" }}
                src=""
              />
            </Grid>
            <Grid>
              <Image
                css={{ width: 160, height: 220, background: "red" }}
                src=""
              />
            </Grid>
          </Grid.Container>
        </Grid> */}
        {/* <Grid css={{ marginTop: 40 }}>
          <Button
            iconLeftCss={{
              color: "white",
              fontSize: 20,
              mt: 2,
            }}
            icon={<BsFillCloudUploadFill />}
            css={{
              backgroundImage:
                "linear-gradient(87.03deg, #3EBA8D 14.58%, #6475B9 114.7%)",
            }}
          >
            Upload Image
          </Button>
        </Grid> */}
        <Grid.Container css={{ marginTop: 40, padding: 10 }} xs={12}>
          <Grid xs={6}>
            <Button
              type="reset"
              onClick={() => setPfp(undefined)}
              css={{ background: "#AF5858" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} justify="flex-end">
            <Button type="submit" css={{ background: "#6475B9" }}>
              Save Changes
            </Button>
          </Grid>
        </Grid.Container>
      </Grid.Container>
    </Box>
  );
};

export default Personal;
