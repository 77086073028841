import { Id, toast } from "react-toastify";
import bs58 from "bs58";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { WalletContextState } from "@solana/wallet-adapter-react";
import {
  Profile,
  Project,
  SendBulkTransactionsQuery,
  User,
} from "@honeycomb-protocol/edge-client";
import { SocialInfo } from "@honeycomb-protocol/hive-control";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { createGenericFile } from "@metaplex-foundation/umi";
import { irysUploader } from "@metaplex-foundation/umi-uploader-irys";
import base58 from "bs58";

import type { AsyncActions } from "../actions/types.ts";
import { wait } from "../../utils/index.ts";
import { HPL_PROJECT, PAYER_DRIVER, UMI } from "../../config.ts";
import { auth } from "../actions/index.ts";
import type { AuthState, HoneycombState } from "../types.ts";
import { VersionedTransaction } from "@solana/web3.js";

const actionFactory = (actions: AsyncActions) => {
  const setWallet = createAsyncThunk<WalletContextState, WalletContextState>(
    "honeycomb/identity",
    async (wallet, { rejectWithValue, fulfillWithValue, getState }) => {
      try {
        return fulfillWithValue(wallet);
      } catch (error) {
        // Handle any errors that may occur during wallet connection
        console.error("Error injecting wallet to honeycomb:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const loadIdentityDeps = createAsyncThunk<boolean, WalletContextState>(
    "honeycomb/loadIdentityDeps",
    async (wallet, { getState, dispatch }) => {
      const {
        auth: { authToken },
      } = getState() as { honeycomb: HoneycombState; auth: AuthState };
      if (!wallet?.publicKey) return false;

      (
        await dispatch(
          fetchSearchedUser({
            wallet: wallet,
          })
        )
      ).payload as User;
      if (!authToken) await dispatch(authenticate(wallet));
      return true;
    }
  );

  const fetchProfile = createAsyncThunk<Profile>(
    "honeycomb/fetchProfile",
    async (_, { fulfillWithValue, rejectWithValue, getState }) => {
      const { edgeClient, user } = (getState() as { honeycomb: HoneycombState })
        .honeycomb;
      if (!user) return rejectWithValue("User not found");
      try {
        const profile = await edgeClient
          .findProfiles({
            userIds: [user.id],
            projects: [HPL_PROJECT.toString()],
          })
          .then((data) => {
            if (!data || !data.profile.length) {
              throw new Error("Profile not Found");
            }
            return data.profile[0] as Profile;
          })
          .catch((e) => {
            console.error("Error fetching Profile:", e);
            throw new Error("Profile not Found");
          });

        return fulfillWithValue(profile) as Profile;
      } catch (error) {
        console.error("Error fetching Profile:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const createUserAndProfile = createAsyncThunk<
    SendBulkTransactionsQuery,
    { username: string; name: string; bio: string; pfp: File | string }
  >(
    "honeycomb/createUserAndProfile",
    async (args, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      const { wallet, edgeClient } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;
      let toastId: Id;
      try {
        if (!wallet?.publicKey || !wallet?.signTransaction) {
          return rejectWithValue("No identity found");
        }

        let pfp: string = args.pfp as any;
        let uploading = false;

        toastId = toast.loading(
          uploading
            ? "Uploading Pfp & Creating User Profile..."
            : "Creating User Profile...",
          { progress: 0 }
        );

        const transaction = (
          await edgeClient.createNewUserWithProfileTransaction({
            wallet: wallet.publicKey.toString(),
            payer: wallet.publicKey.toString(),
            // payer: PAYER_DRIVER.toString(),
            userInfo: {
              bio: args.bio,
              username: args.username,
              pfp,
              name: args.name,
            },
            project: HPL_PROJECT.toString(),
            profileInfo: {
              bio: args.bio,
              pfp,
              name: args.username,
            },
          })
        ).createNewUserWithProfileTransaction;

        transaction.transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(
              bs58.decode(transaction.transaction)
            )
          )
          .then((x) => base58.encode(x.serialize()));

        const user = await edgeClient
          .sendBulkTransactions({
            txs: [transaction.transaction],
            blockhash: transaction.blockhash,
            lastValidBlockHeight: transaction.lastValidBlockHeight,
            options: {
              commitment: "confirmed",
              skipPreflight: true,
            },
          })
          .catch((e) => {
            console.error(
              "an unexpected error occured while creating User Profile",
              e
            );
            toast.update(toastId, {
              autoClose: 5000,
              type: "error",
              render: "an unexpected error occured while creating User Profile",
              progress: 1,
            });
            throw rejectWithValue(e);
          });

        await wait(1000);
        await dispatch(fetchSearchedUser({ wallet })).then(() => {
          toast.update(toastId, {
            autoClose: 5000,
            type: "success",
            render: "Profile Creation Successful!!",
            progress: 1,
          });
        });
        await dispatch(fetchProfile());
        await dispatch(authenticate(wallet));
        return fulfillWithValue(user);
      } catch (error) {
        //@ts-ignore
        toast.update(toastId, {
          autoClose: 50000,
          type: "error",
          render: "an unexpected error occured while creating User Profile",
          // progress: 1,
        });
        console.error("Error Uploading file during Profile Creation:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const updateProfile = createAsyncThunk<
    SendBulkTransactionsQuery,
    { username?: string; bio?: string; pfp?: string | File }
  >(
    "honeycomb/updateProfile",
    async (args, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      let toastId: Id;

      const { wallet, edgeClient, profile } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;

      try {
        if (!profile) throw rejectWithValue("No profile found");
        if (!wallet?.publicKey || !wallet?.signTransaction) {
          return rejectWithValue("No identity found");
        }
        let profileInfo = {};

        let pfp: string = args.pfp as any;
        let uploading = false;

        profileInfo = {
          bio: args.bio || profile.info.bio,
          name: args.username || profile.info.name,
          pfp: pfp || profile.info.pfp,
        };

        toastId = toast.loading(
          uploading
            ? "Uploading Pfp & Updating User Profile..."
            : "Updating User Profile...",
          { progress: 0 }
        );

        const data = await edgeClient.createUpdateProfileTransaction({
          payer: wallet.publicKey.toString(),
          // payer: PAYER_DRIVER.toString(),
          profile: profile.identity,
          info: profileInfo,
        });

        const transaction = data.createUpdateProfileTransaction;

        transaction.transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(
              bs58.decode(transaction.transaction)
            )
          )
          .then((x) => base58.encode(x.serialize()));

        const profileRes = await edgeClient
          .sendBulkTransactions({
            txs: [transaction.transaction],
            blockhash: transaction.blockhash,
            lastValidBlockHeight: transaction.lastValidBlockHeight,
            options: {
              commitment: "processed",
              skipPreflight: true,
            },
          })
          .then(async (res) => {
            toast.update(toastId, {
              autoClose: 5000,
              type: "success",
              render: "Profile Creation Successful!!",
              progress: 1,
            });
            return res;
          })
          .catch((e) => {
            console.error(
              "an unexpected error occured while creating User Profile",
              e
            );
            toast.update(toastId, {
              autoClose: 5000,
              type: "error",
              render: "an unexpected error occured while creating User Profile",
              progress: 1,
            });
            throw rejectWithValue(e);
          });
        await wait(500);
        await dispatch(fetchProfile());
        return fulfillWithValue(profileRes);
      } catch (error) {
        console.error("Error Uploading file during Profile Creation:", error);
        //@ts-ignore
        toast.update(toastId, {
          autoClose: 5000,
          type: "error",
          render: "an unexpected error occured while creating User Profile",
          progress: 1,
        });
        return rejectWithValue(error);
      }
    }
  );

  const updateUser = createAsyncThunk<
    User,
    {
      bio?: string;
      pfp?: File | string;
      name?: string;
      socialInfo?: SocialInfo;
      addWallet?: string;
      removeWallet?: string;
    }
  >(
    "honeycomb/updateUser",
    async (args, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      let toastId: Id;

      const { edgeClient, user, wallet } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;
      const { authToken } = (getState() as { auth: AuthState }).auth;

      try {
        if (!user || !wallet?.publicKey || !wallet.signTransaction)
          throw rejectWithValue("No user found");
        let userInfo = {};

        let pfp = args.pfp;

        toastId = toast.loading("Initializing...", { progress: 0 });

        if (pfp instanceof File) {
          toast.update(toastId, {
            autoClose: false,
            render: "Uploading Pfp...",
            progress: 0.5,
          });
          UMI.use(walletAdapterIdentity(wallet)).use(irysUploader());

          const image = createGenericFile(
            new Uint8Array(await pfp.arrayBuffer()),
            pfp.name,
            {
              uniqueName: pfp.name + Math.random().toString(36).substring(7),
              contentType: pfp.type,
            }
          );

          const [imgUri] = await UMI.uploader.upload([image]);
          pfp = imgUri;
        }

        toast.update(toastId, {
          autoClose: false,
          render: "Updating User...",
          progress: 0.8,
        });

        userInfo = {
          bio: args.bio || user.info.bio,
          name: args.name || user.info.name,
          pfp: pfp || user.info.pfp,
          username: user?.info.username,
        };

        if (!args.addWallet && !args.removeWallet && !userInfo) {
          return rejectWithValue("No data to update");
        }

        const data = await edgeClient.createUpdateUserTransaction(
          args.addWallet
            ? {
                payer: wallet.publicKey.toString(),
                // payer: PAYER_DRIVER.toString(),
                wallets: {
                  add: args.addWallet,
                },
              }
            : args.removeWallet
            ? {
                payer: wallet.publicKey.toString(),
                // payer: PAYER_DRIVER.toString(),
                wallets: {
                  add: args.addWallet,
                },
              }
            : {
                payer: wallet.publicKey.toString(),
                // payer: PAYER_DRIVER.toString(),
                info: userInfo,
                socialInfo: args.socialInfo || user.socialInfo,
              },
          {
            fetchOptions: {
              headers: {
                authorization: `Bearer ${authToken}`,
              },
            },
          }
        );

        const transaction = data.createUpdateUserTransaction;

        transaction.transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(
              bs58.decode(transaction.transaction)
            )
          )
          .then((x) => base58.encode(x.serialize()));

        await edgeClient.sendBulkTransactions({
          txs: [transaction.transaction],
          blockhash: transaction.blockhash,
          lastValidBlockHeight: transaction.lastValidBlockHeight,
          options: {
            commitment: "processed",
            skipPreflight: true,
          },
        });
        await wait(500);
        const updatedUser = (await dispatch(fetchSearchedUser({ wallet })))
          .payload as User;
        toast.update(toastId, {
          autoClose: 5000,
          type: "success",
          render: "User Updated Successful!!",
          progress: 1,
        });
        return fulfillWithValue(updatedUser as User);
      } catch (error) {
        console.error("Error while updating User:", error);
        //@ts-ignore
        toast.update(toastId, {
          autoClose: 5000,
          type: "error",
          render: "an unexpected error occured while updating User",
          progress: 1,
        });
        return rejectWithValue(error);
      }
    }
  );

  const createUser = createAsyncThunk<
    User,
    { username: string; bio: string; pfp: File | string; name: string }
  >(
    "honeycomb/createUser",
    async (args, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      let toastId: Id;

      const { wallet, edgeClient } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;

      try {
        if (!wallet?.publicKey || !wallet?.signTransaction) {
          return rejectWithValue("No identity found");
        }

        let pfp = args.pfp;

        toastId = toast.loading("Initializing...", { progress: 0 });

        if (pfp instanceof File) {
          toast.update(toastId, {
            autoClose: false,
            render: "Uploading Pfp...",
            progress: 0.5,
          });

          UMI.use(walletAdapterIdentity(wallet)).use(irysUploader());

          const image = createGenericFile(
            new Uint8Array(await pfp.arrayBuffer()),
            pfp.name,
            {
              uniqueName: pfp.name + Math.random().toString(36).substring(7),
              contentType: pfp.type,
            }
          );

          const [imgUri] = await UMI.uploader.upload([image]);
          pfp = imgUri;
        }

        toast.update(toastId, {
          autoClose: false,
          render: "Creating User...",
          progress: 0.8,
        });

        const transaction = (
          await edgeClient.createNewUserTransaction({
            wallet: wallet.publicKey.toString(),
            payer: wallet.publicKey.toString(),
            // payer: PAYER_DRIVER.toString(),
            info: {
              bio: args.bio,
              username: args.username,
              pfp,
              name: args.name,
            },
          })
        ).createNewUserTransaction;

        transaction.transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(
              bs58.decode(transaction.transaction)
            )
          )
          .then((x) => base58.encode(x.serialize()));

        await edgeClient.sendBulkTransactions({
          txs: [transaction.transaction],
          blockhash: transaction.blockhash,
          lastValidBlockHeight: transaction.lastValidBlockHeight,
          options: {
            commitment: "confirmed",
            skipPreflight: true,
          },
        });
        await wait(500);
        const user = (await dispatch(fetchSearchedUser({ wallet })))
          .payload as User;
        toast.update(toastId, {
          autoClose: 5000,
          type: "success",
          render: "User Creation Successful!!",
          progress: 1,
        });
        await dispatch(authenticate(wallet));
        return fulfillWithValue(user as User);
      } catch (error) {
        console.error("Error Uploading file during Profile Creation:", error);
        //@ts-ignore
        toast.update(toastId, {
          autoClose: 5000,
          type: "error",
          render: "an unexpected error occured while creating User",
          progress: 1,
        });
        throw rejectWithValue(error);
      }
    }
  );

  const createProfile = createAsyncThunk<
    SendBulkTransactionsQuery,
    { name: string; bio: string; pfp: string | File }
  >(
    "honeycomb/createProfile",
    async (args, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
      const { wallet, edgeClient, user } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;
      // const { authToken } = (getState() as { auth: AuthState }).auth;
      console.log("user", user);

      try {
        if (!wallet?.publicKey || !wallet?.signTransaction) {
          return rejectWithValue("No identity found");
        }

        const { token: authToken } = (await dispatch(authenticate(wallet)))
          .payload as { token: string };

        let pfp: string = args.pfp as any;

        let uploading = false;

        const toastId = toast.loading(
          uploading
            ? "Uploading Pfp & Creating User Profile..."
            : "Creating User Profile...",
          { progress: 0 }
        );

        const data = await edgeClient.createNewProfileTransaction(
          {
            payer: wallet.publicKey.toString(),
            // payer: PAYER_DRIVER.toString(),
            project: HPL_PROJECT.toString(),
            identity: wallet.publicKey.toString(),
            info: {
              bio: args.bio,
              pfp,
              name: args.name,
            },
          },
          {
            fetchOptions: {
              headers: {
                authorization: `Bearer ${authToken}`,
              },
            },
          }
        );

        const transaction = data.createNewProfileTransaction;

        transaction.transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(
              bs58.decode(transaction.transaction)
            )
          )
          .then((x) => base58.encode(x.serialize()));

        const profile = await edgeClient
          .sendBulkTransactions({
            txs: [transaction.transaction],
            blockhash: transaction.blockhash,
            lastValidBlockHeight: transaction.lastValidBlockHeight,
            options: {
              commitment: "processed",
              skipPreflight: true,
            },
          })
          .catch((e) => {
            console.error(
              "an unexpected error occured while creating User Profile",
              e
            );
            toast.update(toastId, {
              autoClose: 5000,
              type: "error",
              render: "an unexpected error occured while creating User Profile",
              progress: 1,
            });
            throw rejectWithValue(e);
          });

        await wait(100);
        await fetchProfile();
        await dispatch(authenticate(wallet));
        toast.update(toastId, {
          autoClose: 5000,
          type: "success",
          render: "Profile Creation Successful!!",
          progress: 1,
        });
        return fulfillWithValue(profile);
      } catch (error) {
        console.error("Error Uploading file during Profile Creation:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const authenticate = createAsyncThunk<
    {
      wallet: string;
      token: string;
      user: User;
    },
    WalletContextState
  >(
    "honeycomb/authenticate",
    async (
      wallet,
      { rejectWithValue, fulfillWithValue, getState, dispatch }
    ) => {
      const { edgeClient, user } = (getState() as { honeycomb: HoneycombState })
        .honeycomb;

      try {
        if (!user) {
          throw new Error("User not found");
        }

        if (!wallet?.signMessage) {
          return rejectWithValue("Wallet not found");
        }

        const res = await edgeClient.authRequest({
          wallet: wallet?.publicKey?.toString()!,
        });

        let sig;
        const message = new TextEncoder().encode(res.authRequest.message);
        try {
          sig = await wallet?.signMessage(message)!;
        } catch (e) {
          await dispatch(auth.logout());
          throw new Error("Signature not found");
        }
        const signature = base58.encode(sig);

        const {
          authConfirm: { accessToken: token },
        } = await edgeClient.authConfirm({
          signature: signature,
          wallet: wallet?.publicKey?.toString()!,
        });

        return fulfillWithValue({
          token: token,
          user,
          wallet: wallet?.publicKey?.toString()!,
        });
      } catch (error) {
        console.error("Error authenticating", error);
        return rejectWithValue(error);
      }
    }
  );

  const fetchSearchedUser = createAsyncThunk<
    User[],
    { name?: string; wallet?: WalletContextState | null | undefined }
  >(
    "honeycomb/fetchSearchedUsers",
    async (
      args: { name?: string; wallet?: WalletContextState | null | undefined },
      { fulfillWithValue, rejectWithValue, getState }
    ) => {
      try {
        const { name, wallet: Wallet } = args || {};
        const {
          honeycomb: { edgeClient, wallet: _wallet },
          auth: { wallet: persistedWallet },
        } = getState() as { honeycomb: HoneycombState; auth: AuthState };
        if (!Wallet && !name) return [];

        let wallet = Wallet || _wallet;

        const users = (
          await edgeClient.findUsers(
            name
              ? { usernames: [name || ""] }
              : { wallets: [persistedWallet || wallet?.publicKey?.toString()!] }
          )
        ).user;
        if (users.length > 0) {
          fulfillWithValue(users);
          return users;
        } else {
          fulfillWithValue([]);
          return [];
        }
      } catch (e) {
        rejectWithValue(e);
        return [];
      }
    }
  );

  const addWallet = createAsyncThunk<any, void>(
    "honeycomb/addWallet",
    async (_, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
      const { user, edgeClient, wallet } = (
        getState() as { honeycomb: HoneycombState }
      ).honeycomb;

      const { authToken } = (getState() as { auth: AuthState }).auth;

      try {
        if (!user || !edgeClient)
          return rejectWithValue("User Or Client not found.");

        if (
          !wallet?.signMessage ||
          !wallet?.publicKey ||
          !wallet?.signTransaction
        )
          return rejectWithValue("Wallet is not connected");

        if (!authToken)
          throw new Error("Not Authenticated with auth driver yet!");

        if (user.wallets.wallets.includes(wallet?.publicKey?.toString()!)) {
          return fulfillWithValue("Wallet already added");
        }

        const toastId = toast.loading("Adding Wallet to User Profile...", {
          progress: 0,
        });

        let {
          createUpdateUserTransaction: {
            blockhash,
            lastValidBlockHeight,
            transaction,
          },
        } = await edgeClient.createUpdateUserTransaction(
          {
            payer: wallet.publicKey?.toString(),
            // payer: PAYER_DRIVER.toString()!,
            wallets: {
              add: wallet.publicKey?.toString(),
            },
          },
          {
            fetchOptions: {
              headers: {
                authorization: `Bearer ${authToken}`,
              },
            },
          }
        );

        transaction = await wallet
          .signTransaction(
            VersionedTransaction.deserialize(bs58.decode(transaction))
          )
          .then((x) => base58.encode(x.serialize()));

        const userRes = await edgeClient
          .sendBulkTransactions({
            txs: [transaction],
            blockhash: blockhash,
            lastValidBlockHeight: lastValidBlockHeight,
            options: {
              commitment: "confirmed",
              skipPreflight: true,
            },
          })
          .catch((e) => {
            console.error("an unexpected error occured while updating User", e);
            toast.update(toastId, {
              autoClose: 5000,
              type: "error",
              render: "an unexpected error while adding wallet.",
              progress: 1,
            });
            throw rejectWithValue(e);
          });
        await wait(800);
        await dispatch(fetchSearchedUser({ wallet }));
        toast.update(toastId, {
          autoClose: 5000,
          type: "success",
          render: "Wallet Added Successfully!!",
          progress: 1,
        });
        return fulfillWithValue(userRes);
      } catch (error) {
        console.error("Error authenticate:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const fetchUserProfiles = createAsyncThunk<Profile[], { userId: number }>(
    "honeycomb/fetchUserProfiles",
    async (
      args: { userId: number },
      { fulfillWithValue, rejectWithValue, getState }
    ) => {
      const { edgeClient, user } = (getState() as { honeycomb: HoneycombState })
        .honeycomb;

      if (!user) return rejectWithValue("User not logged in");

      try {
        const profile = await edgeClient
          .findProfiles({
            userIds: [args.userId],
          })
          .then((data) => {
            if (!data || !data.profile.length) {
              throw new Error("Profile not Found");
            }
            return data.profile as Profile[];
          })
          .catch((e) => {
            console.error("Error fetching Profile:", e);
            throw new Error("Profile not Found");
          });

        return fulfillWithValue(profile) as Profile[];
      } catch (error) {
        // console.error("Error fetching Profile:", error);
        throw rejectWithValue(error);
      }
    }
  );

  const getUserProjects = createAsyncThunk<
    Project[],
    { projectAddresses: string[] }
  >(
    "honeycomb/getUserProjects",
    async (
      args: { projectAddresses: string[] },
      { fulfillWithValue, rejectWithValue, getState }
    ) => {
      const { edgeClient } = (getState() as { honeycomb: HoneycombState })
        .honeycomb;

      try {
        const projects = await edgeClient
          .findProjects({ addresses: args.projectAddresses })
          .then((data) => {
            if (!data || !data.project.length) {
              throw new Error("Projects not Found");
            }
            return data.project as Project[];
          })
          .catch((e) => {
            console.error("Error fetching Projects:", e);
            throw new Error("Projects not Found");
          });

        return fulfillWithValue(projects) as Project[];
      } catch (error) {
        console.error("Error fetching Projects:", error);
        throw rejectWithValue(error);
      }
    }
  );

  return {
    setWallet,
    fetchProfile,
    authenticate,
    loadIdentityDeps,
    createUserAndProfile,
    updateProfile,
    createUser,
    createProfile,
    fetchSearchedUser,
    updateUser,
    addWallet,
    fetchUserProfiles,
    getUserProjects,
  };
};
export default actionFactory;
