import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { ToastContainer, ToastContainerProps } from "react-toastify";

import { customTheme } from "../themes/theme";
import ConfigProvider from "./ConfigProvider";
import WalletProviders from "./WalletProviders";
import GatewayProvider from "./GatewayProvider";
import { store } from "../store";
import Effects from "../effects";

const toastOptions: ToastContainerProps = {
  position: "bottom-left",
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
  theme: "dark",
  style: {
    opacity: 0.8,
  },
};

interface Props {
  children: React.ReactNode;
}
export default function Providers({ children }: Props) {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <NextUIProvider theme={customTheme}>
          <ConfigProvider>
            <WalletProviders>
              <GatewayProvider
                network={
                  process.env.REACT_APP_PROD === "true"
                    ? "mainnet-beta"
                    : "devnet"
                }
              >
                <Effects />
                {children}
                <ToastContainer {...toastOptions} />
              </GatewayProvider>
            </WalletProviders>
          </ConfigProvider>
        </NextUIProvider>
      </BrowserRouter>
    </Provider>
  );
}
