import { Text } from "@nextui-org/react";
import { Profile } from "@honeycomb-protocol/edge-client";

import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import { PlatformType } from "../../layout/MainLayout";

const SidebarItem = ({
  platform,
  onClick,
  activePlatformData,
}: {
  platform: PlatformType;
  onClick: () => void;
  activePlatformData: Profile | undefined;
}) => {
  return (
    <Box
      // as={Link}
      key={Math.random() * 1000}
      // to={{
      //   pathname: ``,
      // }}
      css={{
        display: "block",
        mt: "20px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Flex
        align="center"
        css={{
          gap: "$6",
          //   py: '5px',
          px: "14px",
          minHeight: "50px",
          borderRadius: "10px",
          backgroundColor:
            activePlatformData?.project === platform?.profileInfo?.project
              ? "#4c516c"
              : "rgba(255, 255, 255, 0.1)",
        }}
      >
        {/* {item.sidebarProps.icon && ( */}
        {/* <Flex css={{ " & svg": { maxW: "20px" } }}> */}
        {/* {item.sidebarProps.icon} */}
        {/* </Flex> */}
        {/* )} */}
        {/* {item.sidebarProps.img && ( */}
        {/* <Flex css={{ " & img": { maxW: "30px", borderRadius: "50%" } }}>
          <Box as="img" src={"/assets/images/eth-logo.png"} />
        </Flex> */}
        {/* )} */}
        <Text
          span
          weight={"normal"}
          size={"$base"}
          css={{
            color: "$accents9",
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          {platform?.projectInfo.name}
        </Text>
      </Flex>
    </Box>
  );
};

export default SidebarItem;
