import { Connection, PublicKey } from "@solana/web3.js";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { useWallet } from "@solana/wallet-adapter-react";

function Gateway({
  network,
  children,
}: {
  network: string;
  children: React.ReactNode;
}) {
  const wallet = useWallet();
  const connection = new Connection(
    process.env.REACT_APP_RPC_URL!,
    "processed"
  );
  const gatekeeperNetwork = new PublicKey(
    process.env.REACT_APP_GATEKEEPER_NETWORK!
  );

  return (
    <GatewayProvider
      wallet={wallet}
      gatekeeperNetwork={gatekeeperNetwork}
      cluster={network}
      connection={connection}
    >
      {children}
    </GatewayProvider>
  );
}

export default Gateway;
