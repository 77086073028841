import { Metadata, Metaplex } from "@metaplex-foundation/js";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MetaplexState {
  ownedNfts: Metadata[];
  metaplex: Metaplex | null;
}

const initialState: MetaplexState = {
  ownedNfts: [],
  metaplex: null,
};

export const slice = createSlice({
  name: "metaplex",
  initialState,
  reducers: {
    setOwnedNfts: (state, action: PayloadAction<Metadata[]>) => {
      state.ownedNfts = action.payload;
    },
    setMetaplex: (state, action: PayloadAction<Metaplex>) => {
      state.metaplex = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
