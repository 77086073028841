import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type tabState = {
  tabState: string;
};

const initialState: tabState = {
  tabState: '',
};

export const tabStateSlice = createSlice({
  name: 'tabState',
  initialState,
  reducers: {
    setTabState: (state, action: PayloadAction<string>) => {
      state.tabState = action.payload;
    },
  },
});

export const { setTabState } = tabStateSlice.actions;

export default tabStateSlice.reducer;
