import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";

import { useHoneycomb } from "../hooks";
import { useSearchParams } from "react-router-dom";

export default function Effects() {
  const wallet = useWallet();
  const {
    setWallet,
    loadIdentityDeps,
    wallet: stateWallet,
    user,
  } = useHoneycomb();
  const [params, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      wallet?.publicKey &&
      stateWallet?.publicKey &&
      stateWallet?.publicKey?.equals(wallet?.publicKey)
    ) {
      return;
    }
    console.log("EFFECT 1: Setting Wallet");
    setWallet(wallet);
  }, [wallet]);

  useEffect(() => {
    const load = async () => {
      if (wallet?.publicKey && wallet.connected) {
        console.log("EFFECT 2: loadIdentityDeps");
        await loadIdentityDeps(wallet);
        params.delete("username");
        setSearchParams({ username: user?.info.username || "" });
      }
    };
    load();
  }, [wallet]);

  return <></>;
}
