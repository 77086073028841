import { configureStore } from '@reduxjs/toolkit';
import appStateSlice from './features/appStateSlice';
import tabStateSlice from './features/tabStateSlice';

export const store = configureStore({
  reducer: {
    appState: appStateSlice,
    tabState: tabStateSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
