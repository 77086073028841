import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Grid, Text } from "@nextui-org/react";
import {
  Profile as ProfileType,
  Project,
  User,
} from "@honeycomb-protocol/edge-client";

import { useHoneycomb } from "../hooks";
import { Box } from "../components/ui/Box";
import { Navbar, Profile, Sidebar } from "../components";

export interface PlatformType {
  profileInfo: ProfileType | undefined;
  projectInfo: Project;
}

const MainLayout = () => {
  const {
    user: currentUser,
    fetchSearchedUser,
    fetchUserProfiles,
    getUserProjects,
  } = useHoneycomb();
  const [params] = useSearchParams();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<Boolean>(false);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [activePlatformData, setActivePlatformData] = useState<ProfileType>();

  const fetchuser = useMemo(async () => {
    if (
      params.get("username") &&
      params.get("username") !== currentUser?.info.username &&
      params.get("username") !== ""
    ) {
      setLoading(true);
      const userInfo = (
        await fetchSearchedUser({ name: params.get("username") || "" })
      ).payload as User[];

      if (userInfo.length > 0) {
        const userProfiles = await fetchUserProfiles(userInfo[0]?.id);
        if (userProfiles && userProfiles.length > 0) {
          const projectAddresses = userProfiles.map(
            (profile) => profile.project
          );
          const userProjects = await getUserProjects(projectAddresses);
          const projects = userProjects.map((project) => {
            const profileInfo = userProfiles.find(
              (profile) => profile.project === project.address
            );
            return { profileInfo, projectInfo: project };
          });
          setPlatforms(projects);
        }
      }

      setLoading(false);
      setUser(userInfo[0] as User);
      return userInfo[0] as User;
    } else {
      setLoading(true);
      if (currentUser?.id !== undefined) {
        const userProfiles = await fetchUserProfiles(currentUser?.id!);
        if (userProfiles && userProfiles?.length > 0) {
          const projectAddresses = userProfiles.map(
            (profile) => profile.project
          );
          const userProjects = await getUserProjects(projectAddresses);
          const projects = userProjects.map((project) => {
            const profileInfo = userProfiles.find(
              (profile) => profile.project === project.address
            );
            return { profileInfo, projectInfo: project };
          });
          setPlatforms(projects);
        }
      }
      setLoading(false);
      setUser(currentUser as User);
      return currentUser as User;
    }
  }, [params]);

  useEffect(() => {
    setActivePlatformData(undefined);
  }, [params.get("username")]);

  return (
    <Box
      css={{
        background: `url('/images/layout-bg.png')`,
        backgroundPosition: "top left",
      }}
    >
      <Container>
        <Navbar />
        <Box
          css={{
            my: "$10",
            background: "rgba(0, 0, 0, 0.4)",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(187.5px)",
            borderRadius: "19px 19px 0px 0px",
            p: "$15",
            pt: "$15",
            height: "100%",
          }}
        >
          <Profile loading={loading} user={user} />
          <Grid.Container css={{ mt: "$15" }}>
            <Grid xs={12} md={3}>
              <Sidebar
                user={user}
                platforms={platforms}
                setActivePlatformData={setActivePlatformData}
                activePlatformData={activePlatformData}
              />
            </Grid>
            <Grid
              css={{
                minHeight: "100%",
                borderRadius: "20px",
                overflow: "hidden",
                position: "relative",
                zIndex: 2,

                border: "1px solid rgba(255, 255, 255, 0.2)",
                "&:before": {
                  content: "''",
                  position: "absolute",
                  width: "100%",
                  height: "1000%",
                  backdropFilter: "blur(170px)",
                  zIndex: -1,
                  borderRadius: "10px",
                  background: "#ffffff0d",
                  overflowY: "hidden",
                },
              }}
              xs={12}
              md={9}
            >
              <Box
                css={{
                  p: "$15",
                  width: "100%",
                  color: "white",
                  columnGap: "$20",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!activePlatformData ? (
                  <Text
                    css={{
                      color: "gray",
                      fontSize: "15px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No data to show
                  </Text>
                ) : (
                  <>
                    <img
                      src={
                        (activePlatformData && activePlatformData.info?.pfp) ??
                        ""
                      }
                      alt="pfp"
                      height="60px"
                      width="200px"
                      style={{ boxShadow: "0px 0px 40px 0px #776868" }}
                    />
                    <Box
                      css={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        lineHeight: "1",
                        columnGap: "$20",
                        rowGap: "$10",
                      }}
                    >
                      {[
                        { name: "Name", value: activePlatformData?.info.name },
                        { name: "Bio", value: activePlatformData?.info.bio },
                        {
                          name: "Achivements",
                          value:
                            activePlatformData?.platformData.achievements
                              .length ?? null,
                        },
                        {
                          name: "XP",
                          value: activePlatformData?.platformData.xp,
                        },
                      ].map((item) => (
                        <Text css={{ fontWeight: "bold", fontSize: "16px" }}>
                          {item.name} :{" "}
                          <span
                            style={{
                              fontWeight: "lighter",
                              color: "lightgray",
                            }}
                          >
                            {item.value}
                          </span>
                        </Text>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid.Container>
        </Box>
      </Container>
    </Box>
  );
};

export default MainLayout;
