import { Button, Text } from "@nextui-org/react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Profile, User } from "@honeycomb-protocol/edge-client";

import SidebarItem from "./SidebarItem";
import { Box } from "../ui/Box";
// import SidebarItemCollapse from "./SidebarItemCollapse";
import { useHoneycomb, useSolpatrol, useSteam } from "../../hooks";
import { IRT, RouteType } from "../../routes/appRoutes";
import { PlatformType } from "../../layout/MainLayout";

interface IRouteType extends IRT {
  child?: Map<string, IRT>;
}

const Sidebar = ({
  user,
  platforms,
  setActivePlatformData,
  activePlatformData,
}: {
  user?: User;
  platforms: PlatformType[];
  setActivePlatformData: (data: Profile) => void;
  activePlatformData: Profile | undefined;
}) => {
  const { fetchingUser, wallet } = useHoneycomb();

  const [wallets, setWallets] = useState<Map<string, IRouteType>>(new Map());
  const { profiles: steamProfiles, searchedProfiles: steamSearchedProfiles } =
    useSteam();
  const { profiles: solPatrolProfiles, searchedProfiles: solSearchedProfiles } =
    useSolpatrol();
  const navigate = useNavigate();
  const location = useLocation();

  const profiles = useMemo(() => {
    if (fetchingUser) {
      return steamSearchedProfiles;
    }
    return steamProfiles;
  }, [fetchingUser, steamProfiles, steamSearchedProfiles]);

  const solProfiles = useMemo(() => {
    if (fetchingUser) {
      return solSearchedProfiles;
    }
    return solPatrolProfiles;
  }, [fetchingUser, solPatrolProfiles, solSearchedProfiles]);

  // useEffect(() => {
  //   const w = new Map(wallets);
  //   (async () => {
  //     if (Object.keys(profiles).length > 0) {
  //       w.set("steam", {
  //         path: "/",
  //         element: <Outlet />,
  //         state: "steam",
  //         child: new Map(),
  //         sidebarProps: {
  //           displayText: "Steam",
  //           img: require("../../assets/images/steam.png"),
  //         },
  //       });
  //       Object.entries(profiles).forEach(([idStr, valueStr]) => {
  //         w.get("steam")?.child?.set(idStr, {
  //           path: "/steam/" + idStr,
  //           element: (
  //             <Box>
  //               <NewTab module="steam" />
  //               <Steam />
  //             </Box>
  //           ),
  //           state: "steam.steam",
  //           sidebarProps: {
  //             displayText:
  //               (valueStr?.data?.get("steamUsername") as string) ||
  //               valueStr?.steamUsername ||
  //               idStr,
  //             img: require("../../assets/images/steam.png"),
  //           },
  //         });
  //       });
  //     }

  //     if (Object.keys(solProfiles).length > 0) {
  //       w.set("solpatrol", {
  //         path: "/",
  //         element: <Outlet />,
  //         state: "solpatrol",
  //         child: new Map(),
  //         sidebarProps: {
  //           displayText: "Solpatrol",
  //           img: require("../../assets/images/solpatrol.png"),
  //         },
  //       });

  //       Object.keys(solProfiles).forEach((idStr) => {
  //         w.get("solpatrol")?.child?.set(idStr, {
  //           path: "/solpatrol/" + idStr,
  //           element: (
  //             <Box>
  //               <NewTab module="solpatrol" />
  //               <Steam />
  //             </Box>
  //           ),
  //           state: "solana.solpatrol",
  //           sidebarProps: {
  //             displayText: idStr,
  //             img: require("../../assets/images/solpatrol.png"),
  //           },
  //         });
  //       });
  //     }
  //     setWallets(w);
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   // }, [user, profiles, solProfiles]);
  // }, [user?.wallets?.wallets, profiles, solProfiles]);

  const isExistsRoute = useCallback(() => {
    if (
      !fetchingUser &&
      (location.pathname.includes("steam") ||
        location.pathname.includes("solpatrol"))
    )
      return true;
    return false;
  }, [location.pathname, fetchingUser]);

  useEffect(() => {
    if (!Array.from(wallets.values()).length) {
      // if (isExistsRoute()) return navigate(`/${user?.username}`);
    } else {
      const wallet = Array.from(wallets.values()).slice(0, 1);
      const walletId = wallet[0].child?.keys().next().value;
      if (!isExistsRoute()) {
        if (
          location.pathname.includes("steam") ||
          location.pathname.includes("solpatrol")
        )
          return;
        else
          navigate(
            `/${fetchingUser || user?.info?.username || ""}/${
              wallet[0].state
            }/${walletId}`
          );
      }
    }
  }, [
    isExistsRoute,
    navigate,
    user?.info?.username,
    wallets,
    fetchingUser,
    location.pathname,
  ]);

  const convertToObj = useCallback((route: IRouteType): RouteType => {
    return { ...route, child: Array.from(route.child?.values() || []) };
  }, []);

  return (
    <Box
      as="ul"
      css={{
        "@xs": {
          width: "100%",
          marginBottom: "$10",
        },
        "@md": {
          width: "80%",
        },
      }}
    >
      <Text css={{ fontWeight: 700, fontSize: "22px", lineHeight: "34px" }}>
        Platforms
      </Text>
      {/* {Array.from(wallets.values()).map((route, index) =>
        route.sidebarProps ? (
          route.child ? (
            <SidebarItemCollapse item={convertToObj(route)} key={index} />
          ) : ( */}
      {platforms.length === 0 ? (
        <Text css={{ color: "LightGray" }}>No platforms connected</Text>
      ) : (
        platforms?.map((platform: PlatformType, ind) => (
          <SidebarItem
            platform={platform}
            onClick={() => {
              setActivePlatformData(platform.profileInfo as Profile);
            }}
            activePlatformData={activePlatformData}
            key={ind}
          />
        ))
      )}
      {/* )
        ) : null
      )} */}
      {!fetchingUser &&
        wallet?.publicKey &&
        user?.wallets.wallets.includes(wallet.publicKey.toString()) && (
          <Button
            css={{
              mt: "$10",
              width: "100%",
              padding: "32px 40px",
              background: "#5062AB",
              borderRadius: "15px",
              fontWeight: "bold",
              fontSize: "18px",
              display: "flex",
              "& svg": {
                mr: "$5",
                ml: "$8",
                height: "25px",
                width: "25px",
              },
            }}
            onPress={() => navigate("/edit/socials")}
          >
            Connect a Platform
          </Button>
        )}
    </Box>
  );
};

export default Sidebar;
