import axios from "axios";
import { useHoneycomb } from "./useHoneycomb";

export function useTwitter() {
  const { user, edgeClient, authToken } = useHoneycomb();

  const AuthenticateTwitter = async () => {
    if (!edgeClient || !user) return;
    await axios
      .get(process.env.REACT_APP_TWITTER_REDIRECT_URI!, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        console.error("error while authenticating twitter.", e);
      });
  };

  return {
    AuthenticateTwitter,
  };
}
