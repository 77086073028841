import { Text } from "@nextui-org/react";
import { Box } from "../../ui/Box";
import { Flex } from "../../ui/Flex";
import { SteamProfile } from "../../../contexts/SteamProvider";

export interface IFriendList {
  friendSince: number;
  image: string;
  index: number;
  locountrycode: string;
  name: string;
  relationship: string;
  steamId: string;
  updatedAt: string;
}

export const FriendList = ({
  profile,
}: {
  profile: SteamProfile | undefined;
}) => {
  return (
    <Box>
      <Box css={{ mt: "$15" }}>
        <Text css={{ fontWeight: 600 }}>All Friends</Text>
        <Box
          css={{
            mt: "$10",
            background: "rgba(0, 0, 0, 0.2)",

            p: "20px",
            borderRadius: "15px",
          }}
        >
          <Flex justify="end">
            <Text
              css={{
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.4)",
                cursor: "pointer",
              }}
            >
              See All
            </Text>
          </Flex>

          <Box
            css={{
              mt: "$5",
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: "$15",
            }}
          >
            {!!profile?.friends?.length &&
              profile?.friends.map((e, index) => {
                return (
                  <Box
                    key={index}
                    css={{
                      borderRadius: "20px",
                      background:
                        e.image &&
                        `url('${e.image
                          .split(".")
                          .slice(0, e.image.split(".").length - 1)
                          .join(".")}_full.${
                          e.image.split(".")[e.image.split(".").length - 1]
                        }')`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      position: "relative",
                      minHeight: "150px",
                    }}
                  >
                    <Box
                      css={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        position: "absolute",
                        bottom: "0",
                        height: "100%",
                        width: "100%",
                        borderRadius: "20px",

                        background:
                          "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.4) 70.83%, rgba(0, 0, 0, 0.8) 85.42%);",
                      }}
                    >
                      <Text css={{ fontSize: "18px", fontWeight: 600 }}>
                        {e.name}
                      </Text>
                      {/* <Text css={{ pb: "$12", color: "#FE5D5D" }}>
                    
                  </Text> */}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
