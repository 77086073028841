import { Button, Grid, Image, Text } from "@nextui-org/react";
import { AiFillVideoCamera } from "react-icons/ai";
import { useGateway, GatewayStatus } from "@civic/solana-gateway-react";

import { Box } from "../../ui/Box";

const Verification = () => {
  const { requestGatewayToken, gatewayStatus } = useGateway();

  return (
    <Box css={{ p: 30, borderRadius: 20, width: "100%" }}>
      <>
        <Box css={{ mt: 0, width: "100%" }}>
          <Grid.Container gap={4} direction={"column"}>
            <Grid>
              <Box css={{ display: "flex", alignItems: "center" }}>
                <Text css={{ m: 0 }} h3>
                  Proof your identity through civic
                </Text>
                <Image
                  css={{
                    ml: 10,
                    width: 40,
                    height: 40,
                  }}
                  containerCss={{ m: 0 }}
                  src={require("../../../assets/images/orangeVerification.png")}
                />
              </Box>
              <Text css={{ wordSpacing: 3, mt: 10, fontSize: 14 }}>
                Proof your identity on your HoneyComb Profile to earn this
                badge.
              </Text>
              <Text css={{ wordSpacing: 3, mt: 30, fontSize: 14 }}>
                Take a video selfie to prove that this is your profile.
              </Text>
              <Button
                onPress={requestGatewayToken}
                iconLeftCss={{
                  fontSize: 22,
                }}
                icon={<AiFillVideoCamera />}
                auto
                css={{
                  backgroundImage:
                    "linear-gradient(87.03deg, #3EBA8D 14.58%, #6475B9 114.7%)",
                  fontSize: 18,
                  mt: 10,
                  pl: 60,
                  pr: 60,
                  pt: 25,
                  pb: 25,
                }}
              >
                {GatewayStatus[gatewayStatus]}
              </Button>
            </Grid>
          </Grid.Container>
        </Box>
      </>
    </Box>
  );
};

export default Verification;
