import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, solpatrolActions } from "../store";
import { projects } from "../config";
import { useHoneycomb } from "./useHoneycomb";
import { toast } from "react-toastify";
import * as web3 from "@solana/web3.js";
import { Profile } from "@honeycomb-protocol/edge-client";
import { SolPatrolCollectible, TokenAccountInfo } from "../store/solpatrol";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Metadata, Metaplex } from "@metaplex-foundation/js";
import { TokenStandard } from "@metaplex-foundation/mpl-token-metadata";

export function useSolpatrol() {
  const dispatch = useDispatch();
  const profiles = useSelector((state: RootState) => state.solpatrol.profiles);
  const loadings = useSelector((state: RootState) => state.solpatrol.loadings);
  const searchedProfiles = useSelector(
    (state: RootState) => state.solpatrol.searchedProfiles
  );
  // done
  const { honeycomb, user, searchedUser, searchingUser } = useHoneycomb();
  const PROJECT = React.useMemo(() => {
    if (!honeycomb?.cluster) return null;
    return projects[
      honeycomb.cluster === "custom" ? "mainnet-beta" : honeycomb.cluster
    ].solpatrol;
  }, [honeycomb?.cluster]);

  const API_URL = React.useMemo(() => {
    if (process.env.REACT_APP_HONEYCOMB_ENV === "local")
      return "http://localhost:4002";
    return "https://missions-driver.honeycombprotocol.com";
  }, []);

  interface IApiStats {
    averageRewardPerMission: {
      Bounty: number;
      Ammo: number;
      Food: number;
      Gems: number;
      "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1": number;
    };
    highestRewardsEarned: {
      Bounty: number;
      Ammo: number;
      Food: number;
      Gems: number;
      "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1": number;
    };
    missions: {
      [key: string]: number;
    };
    nfts: {
      [key: string]: {
        Bounty: number;
        Ammo: number;
        Food: number;
        Gems: number;
        "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1": number;
      };
    };
    totalMissionsCompleted: number;
    totalRewardsEarned: {
      Bounty: number;
      Ammo: number;
      Food: number;
      Gems: number;
      "3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1": number;
    };
  }

  const createProfile = React.useCallback(
    async (wallet: web3.PublicKey) => {
      if (!honeycomb || !user || !PROJECT) return;
      if (
        !user.primaryWallet.equals(wallet) &&
        !user.secondaryWallets.find((w) => w.equals(wallet))
      ) {
        // const wallet = honeycomb.identity().publicKey;
        toast.error("Please connect with a wallet that is linked to user.", {
          autoClose: 5000,
        });
        return;
      }

      const toastId = toast.loading("Fething latest profile info...", {
        progress: 0.5,
      });
      await honeycomb
        .identity()
        .fetch()
        .profile(PROJECT, user?.address, wallet)
        .then(() => {
          toast.update(toastId, {
            type: "success",
            render:
              "Fetched profile, Your solpatrol record will be updated soon.",
            progress: 1,
            autoClose: 5000,
          });
          toast.success(
            "Fetched profile, Your solpatrol record will be updated soon."
          );
        })
        .catch((_) => {
          toast.update(toastId, {
            render: "Creating Profile....",
          });
          return honeycomb
            .identity()
            .create()
            .profile(PROJECT, wallet)
            .then(() => {
              toast.update(toastId, {
                type: "success",
                render:
                  "Created profile, Your solpatrol record will be updated soon.",
                progress: 1,
                autoClose: 5000,
              });
              toast.success(
                "Created profile, Your solpatrol record will be updated soon."
              );
            });
        })
        .catch(() => {
          toast.update(toastId, {
            type: "error",
            render: "Failed to create profile.",
            progress: 1,
            autoClose: 5000,
          });
          toast.error("Failed to create profile.");
          // console.error(e);
        });
    },
    [PROJECT, honeycomb, user]
  );

  const availableNftsCheckingJson = React.useCallback(
    async (availableNfts: SolPatrolCollectible[]) => {
      return Promise.all(
        availableNfts.map(async (item) => {
          if (item.jsonLoaded && !!Object.entries(item.json || {}).length)
            return item;
          if (!!Object.entries(item.json || {}).length)
            return { ...item, jsonLoaded: true };
          const json = await fetch(item.uri, {
            method: "GET",
            redirect: "follow",
          })
            .then((e) => e.json())
            .catch(() => {
              return {};
            });
          return { ...item, json, jsonLoaded: Object.keys(json).length > 0 };
        })
      );
    },
    []
  );

  const fetchCollectibles = React.useCallback(
    async (project, walletAddress: web3.PublicKey) => {
      if (!honeycomb?.connection) return;
      const ownedTokenAccounts: TokenAccountInfo[] = await honeycomb.connection
        .getParsedTokenAccountsByOwner(walletAddress, {
          programId: TOKEN_PROGRAM_ID,
        })
        .then((x) =>
          x.value
            .map((x) => ({
              ...x.account.data.parsed.info,
              tokenMint: new web3.PublicKey(x.account.data.parsed.info.mint),
            }))
            .filter((x) => x.tokenAmount.uiAmount > 0)
        );

      const mx = new Metaplex(honeycomb.connection);
      const ownedNfts = await mx
        .nfts()
        .findAllByMintList({
          mints: ownedTokenAccounts.map((x) => x.tokenMint),
        })
        .then((nfts) =>
          (nfts.filter((x) => x?.model === "metadata") as Metadata[])
            .map((x) => {
              return {
                ...x,
                ...ownedTokenAccounts.find(
                  (y) => y.tokenMint.toString() === x.mintAddress.toString()
                ),
              } as SolPatrolCollectible;
            })
            .filter((x) => {
              if (
                x.tokenStandard &&
                x.tokenStandard === TokenStandard.ProgrammableNonFungible
              ) {
                return true;
              }
              return x.state !== "frozen";
            })
        );

      let filteredNfts: SolPatrolCollectible[] = [];

      filteredNfts = [
        ...filteredNfts,
        ...ownedNfts.filter(
          (nft) =>
            (nft.collection &&
              project.collections.length &&
              nft.collection.verified &&
              !!project.collections.find(
                (x) => nft.collection && x.equals(nft.collection.address)
              )) ||
            (!!nft.creators.length &&
              !!project.creators.length &&
              nft.creators.some(
                (creator) =>
                  creator.verified &&
                  project.creators.find((x) => x.equals(creator.address))
              ))
        ),
      ];

      filteredNfts = filteredNfts.filter(
        (nft, index, self) =>
          self.findIndex((t) => t.address.equals(nft.address)) === index
      );

      filteredNfts = await availableNftsCheckingJson(filteredNfts);

      return filteredNfts;
    },
    [availableNftsCheckingJson, honeycomb?.connection]
  );

  const profile = React.useCallback(
    async (wallet: web3.PublicKey, forceRefetch: boolean = false) => {
      if (
        !honeycomb ||
        !PROJECT ||
        searchingUser ||
        !!loadings[wallet.toString()]
      )
        return;
      let profile = profiles[wallet.toString()];
      if (!forceRefetch && profile) return profile;
      dispatch(solpatrolActions.setLoadings({ [wallet.toString()]: true }));
      profile = (await honeycomb
        .identity()
        .fetch()
        .profile(PROJECT, user?.address, wallet)
        .catch(() => {
          // console.error(e);
          dispatch(
            solpatrolActions.setLoadings({ [wallet.toString()]: "failed" })
          );
          return undefined;
        })) as Profile;
      if (profile) {
        const profileIdentity = profile.profileIdentity;
        profile.walletAddress =
          profileIdentity.__kind === "Wallet" ? profileIdentity.key : wallet;

        let {
          data,
        }: {
          data: IApiStats;
        } = await honeycomb
          .http()
          .get(
            `${API_URL}/stats?missionPool=5rRC9pzmDSwW3ceJZJf7Xf4hndC6Tma2x7UJNGk6UNrw&wallet=` +
              profile.walletAddress.toString()
          )
          .catch((e) => {
            console.log("apierror", e);
            return {
              data: {
                averageRewardPerMission: {},
                highestRewardsEarned: {},
                missions: {},
                nfts: {},
                totalMissionsCompleted: 0,
                totalRewardsEarned: {},
              },
            };
          });
        profile.stats = data;
        profile.collectibles = await fetchCollectibles(
          honeycomb.project(PROJECT),
          profile.walletAddress || honeycomb.identity().address
        );
        dispatch(
          solpatrolActions.setLoadings({ [wallet.toString()]: "fetched" })
        );
        dispatch(
          solpatrolActions.setProfiles({
            ...profiles,
            [wallet.toString()]: profile,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.address, honeycomb, PROJECT, searchingUser, loadings]
  );
  const searchedProfile = React.useCallback(
    async (wallet: web3.PublicKey, forceRefetch: boolean = false) => {
      if (!honeycomb || !PROJECT || !searchingUser) return;
      let profile = searchedProfiles[wallet.toString()];
      if (!forceRefetch && profile) return profile;
      profile = (await honeycomb
        .identity()
        .fetch()
        .profile(PROJECT, searchedUser?.address, wallet)
        .catch(() => {
          // console.error(e);
          return undefined;
        })) as Profile;
      if (profile) {
        const profileIdentity = profile.profileIdentity;
        profile.walletAddress =
          profileIdentity.__kind === "Wallet" ? profileIdentity.key : wallet;

        let {
          data,
        }: {
          data: IApiStats;
        } = await honeycomb
          .http()
          .get(
            `${API_URL}/stats?missionPool=5rRC9pzmDSwW3ceJZJf7Xf4hndC6Tma2x7UJNGk6UNrw&wallet=` +
              profile.walletAddress.toString()
          )
          .catch((e) => {
            console.log("apierror", e);
            return {
              data: {
                averageRewardPerMission: {},
                highestRewardsEarned: {},
                missions: {},
                nfts: {},
                totalMissionsCompleted: 0,
                totalRewardsEarned: {},
              },
            };
          });
        profile.stats = data;
        profile.collectibles = await fetchCollectibles(
          honeycomb.project(PROJECT),
          profile.walletAddress || honeycomb.identity().address
        );
        dispatch(
          solpatrolActions.setSearchedProfiles({
            ...searchedProfiles,
            [wallet.toString()]: profile,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [honeycomb, PROJECT, searchingUser, searchedUser]
  );

  const initiliazeProfileThroughUser = React.useCallback(() => {
    if (!user || !honeycomb || !PROJECT || searchingUser) return;
    Promise.all(
      [user.primaryWallet, ...user.secondaryWallets].map(async (wallet) =>
        profiles[wallet.toString()]
          ? profiles[wallet.toString()]
          : await profile(wallet)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, honeycomb, PROJECT, profiles, searchingUser]);

  const initiliazeProfileThroughSearchedUser = React.useCallback(() => {
    if (!honeycomb || !PROJECT || !searchingUser || !searchedUser) return;
    Promise.all(
      [searchedUser.primaryWallet, ...searchedUser.secondaryWallets].map(
        async (wallet) =>
          searchedProfiles[wallet.toString()]
            ? searchedProfiles[wallet.toString()]
            : await searchedProfile(wallet)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [honeycomb, PROJECT, searchedProfiles, searchedUser, searchingUser]);

  const clearSearchedData = React.useCallback(() => {
    dispatch(solpatrolActions.setSearchedProfiles({}));
  }, [dispatch]);

  return {
    createProfile,
    availableNftsCheckingJson,
    fetchCollectibles,
    profile,
    profiles,
    searchedProfiles,
    initiliazeProfileThroughUser,
    initiliazeProfileThroughSearchedUser,
    clearSearchedData,
  };
}
