import React from "react";
import { Text, Grid, Image } from "@nextui-org/react";
import { FiUser } from "react-icons/fi";
import { BsWallet2 } from "react-icons/bs";
import { AiOutlineCheck, AiOutlineShareAlt } from "react-icons/ai";
import { IconBaseProps } from "react-icons/lib";
import { Box } from "../components/ui/Box";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../components";

const EditProfileLayout = () => {
  const location = useLocation();
  const routes: {
    name: string;
    path: string;
    icon: (props: IconBaseProps) => React.ReactNode;
  }[] = [
      {
        name: "Personal Information",
        path: "/edit/personal",
        icon: (props) => <FiUser {...props} />,
      },
      {
        name: "Wallets",
        path: "/edit/wallets",
        icon: (props) => <BsWallet2 {...props} />,
      },
      {
        name: "Socials",
        path: "/edit/socials",
        icon: (props) => <AiOutlineShareAlt {...props} />,
      },
      {
        name: "Verification",
        path: "/edit/verification",
        icon: (props) => <AiOutlineCheck {...props} />,
      },
    ];



  return (
    <>
      <Box css={{ p: 30 }}>
        <Navbar />
      </Box>
      <Box
        css={{
          background: "rgba(0, 0, 0, 0.4)",
          border: "1px solid rgba(255, 255, 255, 0.15)",
          backdropFilter: "blur(187.5px)",
          borderRadius: "19px 19px 0px 0px",
          p: "$15",
          pt: "$15",
        }}
      >

        <Box css={{ display: "flex", alignItems: "center" }}>
          <Box as={Link} to="/"
            css={{
              background: "rgba(255,255,255,0.3)",
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              cursor: 'pointer'
            }}
          >
            <Image css={{ width: 10 }} src={require("../assets/images/back.png")} />
          </Box>
          <Text css={{ m: 0, ml: 20 }} h2>
            Edit Profile
          </Text>
        </Box>
        <Box css={{ mt: "$20" }}>
          <Grid.Container>
            <Grid
              direction="column"
              css={{ alignItems: "center", mb: 40 }}
              xs={12}
              md={3}
            >
              {routes.map((e, i: number) => (
                <Box
                  as={Link}
                  key={i}
                  to={e.path}
                  css={{
                    backgroundColor: e.path === location.pathname ? "#1C144A" : "#1C144A66",
                    width: "90%",
                    height: 50,
                    borderRadius: 15,
                    display: "flex",
                    alignItems: "center",
                    mt: i === 0 ? 0 : 10,
                  }}
                >
                  {e.icon({
                    size: 20,
                    style: { color: e.path === location.pathname ? "#00FFA3" : "white", marginLeft: 20 },
                  })}
                  <Text css={{ color: e.path === location.pathname ? "#00FFA3" : "white", m: 0, ml: 20 }} h4>
                    {e.name}
                  </Text>
                </Box>
              ))}
            </Grid>
            <Grid
              css={{ overflowY: "auto", background: "#1C1444", br: 15 }}
              xs={12}
              md={9}
            >
              <Outlet />
            </Grid>
          </Grid.Container>
        </Box>
      </Box>
    </>
  );
};
export default EditProfileLayout;
