import { createTheme } from '@nextui-org/react';

export const customTheme = createTheme({
  type: 'dark',
  theme: {
    colors: {
      // brand colors
      background: '#180F45',
      foreground: 'white',
      text: '#fff',
      // you can also create your own color
      myDarkColor: '#ff4ecd',
      primary: '#4CE4B0',
      secondary: '#EE7171 ',
      warning: '#DABA10 ',
      tertiary: '#A771EE ',
      // ...  more colors
    },
    space: {},
    fonts: {},
    breakpoints: {
      xs: '650px',
      sm: '960px',
      md: '1280px',
      lg: '1600px',
      // we have to look after finish development
      // lg: '1400px',

      xl: '1720px',
    },
  },
});
