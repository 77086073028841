import React from "react";
import { Route, Routes } from "react-router-dom";

import { MainLayout, EditProfileLayout } from "./layout";
import { editProfileRoutes, routes } from "./routes";
import { useHoneycomb } from "./hooks";
import Providers from "./contexts/Providers";
import { ConnectWallet } from "./components";

/// Styles
import "@solana/wallet-adapter-react-ui/styles.css";
import "react-solana/dist/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "cropperjs/dist/cropper.css";

function Gate(props: { children: React.ReactNode }) {
  const { authToken, user } = useHoneycomb();
  const isLogIn = React.useMemo(() => (user ? true : false), [user]);

  return (
    <>
      {!isLogIn && !authToken ? (
        <Routes>
          <Route path="/" element={<ConnectWallet />} />
          {routes}
        </Routes>
      ) : (
        props.children
      )}
    </>
  );
}

function App() {
  return (
    <Providers>
      <Gate>
        <Routes>
          <Route path="/edit" element={<EditProfileLayout />}>
            {editProfileRoutes}
          </Route>
          <Route path="/" element={<MainLayout />}>
            {routes}
          </Route>
        </Routes>
      </Gate>
    </Providers>
  );
}

export default App;
