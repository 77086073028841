export function debounceFactory(cb: Function, timeout = 300) {
  let timer: NodeJS.Timeout;
  return async (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      //@ts-ignore
      cb.apply(this, args);
    }, timeout);
  };
}

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
