import React from "react";
import { getDefaultProvider } from "ethers";
import { WagmiConfig, createClient } from "wagmi";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { useConfig } from "../hooks";

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider("mainnet"),
});
interface Props {
  children: React.ReactNode;
}
export default function WalletProviders({ children }: Props) {
  const config = useConfig();
  React.useEffect(() => {
    config.setRpc("Custom");
  }, [config]);

  const wallets = React.useMemo(() => [], []);

  return (
    <ConnectionProvider endpoint={process.env.REACT_APP_RPC_URL!}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <WagmiConfig client={client}>{children}</WagmiConfig>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
