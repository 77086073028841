import { useState } from "react";
import { Button, Grid, Image, Spinner, Text } from "@nextui-org/react";
import { BsFillTrash3Fill } from "react-icons/bs";

import { Box } from "../../../ui/Box";
import { ISocialsItem } from "../socials";
import { useHoneycomb } from "../../../../hooks";

export type iSocial =
  | "twitter"
  | "facebook"
  | "instagram"
  | "youtube"
  | "twitch";

interface ISocialsItemProps {
  item: ISocialsItem;
}

export const iconUrl = (iconName: iSocial) => {
  switch (iconName) {
    case "twitter":
      return require("../../../../assets/images/twitter.png");
    case "facebook":
      return require("../../../../assets/images/facebook.png");
    case "instagram":
      return require("../../../../assets/images/instagram.png");
    case "youtube":
      return require("../../../../assets/images/youtube.png");
    case "twitch":
      return require("../../../../assets/images/twitch.png");
    default:
      return require("../../../../assets/images/twitter.png");
  }
};
const SocialItem = ({ item }: ISocialsItemProps) => {
  const { user, updateUser } = useHoneycomb();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Grid.Container gap={2}>
      <Grid xs={12} sm={5}>
        {item.username ? (
          <Box
            css={{
              background: "#00000033",
              p: 15,
              width: "100%",
              borderRadius: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text css={{ color: "white", m: 0 }} h5>
              {item.username}
            </Text>
          </Box>
        ) : (
          <Box
            onClick={() => (item.press ? item.press() : null)}
            css={{
              background: "#FFFFFF33",
              p: 15,
              width: "100%",
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: item.name === "twitter" ? "pointer" : "default",
            }}
          >
            <Text css={{ color: "white", m: 0 }} h5>
              Connect
            </Text>
          </Box>
        )}
      </Grid>
      {
        <Grid css={{ display: "flex", alignItems: "center" }} xs={7} sm={4}>
          <Box
            css={{
              width: "100%",
              height: "58px",
              background: "#4A3D8B",
              p: 10,
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              css={{
                height: 25,
                width: 25,
              }}
              containerCss={{ m: 0 }}
              src={iconUrl(item.name)}
            />
            <Text h4 css={{ textTransform: "capitalize", m: 0, ml: 10 }}>
              {item.name}
            </Text>
          </Box>
        </Grid>
      }
      {item.username && (
        <Grid css={{ display: "flex", alignItems: "center" }} xs={5} sm={2}>
          <Button
            icon={<BsFillTrash3Fill />}
            css={{ background: "#843838", height: "58px", fontSize: 18 }}
            onPress={async () => {
              setLoading(true);
              await updateUser({
                ...user?.socialInfo, //@ts-ignore
                socialInfo: { [item.name]: "" },
              });
              setLoading(false);
            }}
            disabled={loading}
          >
            {loading ? <Spinner color="secondary" size="md" /> : "Delete"}
          </Button>
        </Grid>
      )}
    </Grid.Container>
  );
};

export default SocialItem;
