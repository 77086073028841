import React from "react";
import { Button, Text, Tooltip } from "@nextui-org/react";

import { Box } from "../ui/Box";
import { ReactComponent as TwitterIcon } from "../../assets/icons/social-icons/twitter.svg";
import { useHoneycomb } from "../../hooks";

export const SocialLinks = () => {
  const { user } = useHoneycomb();

  const links = React.useMemo(() => {
    const twitterUsername = user?.socialInfo?.twitter?.split(",")[1];
    // const twitterUsername = twitterProfile?.get("twitterUsername");
    return twitterUsername
      ? [
          {
            icon: <TwitterIcon />,
            url: twitterUsername && `https://twitter.com/${twitterUsername}`,
            name: twitterUsername,
          },
        ]
      : [];
  }, []);

  return (
    <Box>
      <Text
        css={{
          fontWeight: 700,
          fontSize: "22px",
          lineHeight: "34px",
          ml: "$5",
          mb: "$2",
        }}
      >
        Socials
      </Text>
      {links.length > 0 ? (
        <Button.Group
          css={{
            "& a": {
              background: "rgba(65, 51, 137, 0.5)",
              borderRight: "2px solid rgba(19, 12, 55, 0.4)",
              p: "$10",
            },
            "& svg": {
              height: "20px",
              width: "20px",
            },
          }}
        >
          {links?.map((link, i) => (
            <Tooltip key={i} content={"@" + link.name}>
              <Button
                as="a"
                href={link.url}
                key={link.url}
                size="xl"
                css={{ mx: "$2" }}
                target="_blank"
              >
                {link.icon}
              </Button>
            </Tooltip>
          ))}
        </Button.Group>
      ) : (
        <Text css={{ color: "gray", ml: "$5" }}>
          No Socials found
        </Text>
      )}
    </Box>
  );
};
