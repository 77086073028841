import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { PublicKey } from "@solana/web3.js";
import { SolanaNetwork } from "react-solana";

export const network: SolanaNetwork = SolanaNetwork.Mainnet;
export const HPL_PROJECT = new PublicKey(
  process.env.REACT_APP_HPL_PROJECT || PublicKey.default
);
export const production = process.env.REACT_APP_PROD
  ? process.env.REACT_APP_PROD === "true"
  : true;
export const localUrl: string = "http://localhost:4001";
export const prodUrl: string = "https://offchain-sdk.honeycombprotocol.com";
export const RPC_List = [
  {
    name: "Custom",
    // url: process.env.REACT_APP_CUSTOM_RPC || 'https://api.metaplex.solana.com',
    url: process.env.REACT_APP_RPC_URL!,
  },
  {
    name: "Sol Patrol",
    url: process.env.REACT_APP_RPC_URL!,
  },
  {
    name: "Solana",
    url: process.env.REACT_APP_RPC_URL!,
  },
  {
    name: "Metaplex",
    url: "https://api.metaplex.solana.com/",
  },
  {
    name: "GenesysGo",
    url: "https://pentacle.genesysgo.net/",
  },
  {
    name: "Serum",
    url: "https://solana-api.projectserum.com/",
  },
];

export const projects = {
  devnet: {
    solpatrol: new PublicKey("7qP7ddEtcdGgDek3H1nSmU7QvzRbkCFXq9KFaS9mUwid"),
    steam: new PublicKey("BnvGJwqAni9XQ6wo4grfLBz9Xa1oMoA7H7nSYwuQbQZo"),
  },
  "mainnet-beta": {
    solpatrol: new PublicKey("7CKTHsJ3EZqChNf3XGt9ytdZXvSzDFWmrQJL3BCe4Ppw"),
    steam: new PublicKey("C7EJmrTKQDtiyk4k4m9z7fnFi3ZAEXViQ5VQ7UF15cgi"),
  },
  testnet: {
    solpatrol: PublicKey.default,
    steam: PublicKey.default,
  },
};
export const HONEYCOMB_ENV = process.env.REACT_APP_HONEYCOMB_ENV || "main";

export const UMI = createUmi(RPC_List[1].url);

export const PAYER_DRIVER =
  process.env.REACT_APP_DRIVER_PUB_KEY ||
  "BkoJLLqagMPx5Dv2CpxwxEuTKR9DZQ85puq2zwKGdTJo";
