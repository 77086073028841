import { Text } from "@nextui-org/react";
import { Box } from "../../ui/Box";
import { Flex } from "../../ui/Flex";
import { useEffect, useState } from "react";
import { SteamProfile } from "../../../contexts/SteamProvider";


export interface ICollectible {

  app_id: number,

  gameImage: string,
  collectibles: {
    assetId: string,
    classId: string,
    amount: number,
    iconUrl: string,
    name: string,
    type: string
  }[],
  gameName: string,

}

export const Collectibles = ({ profile }: { profile: SteamProfile | undefined }) => {
  const [collectibles, setCollectibles] = useState<ICollectible[]>([]);
  const [totalValueOfInventory, setTotalValueOfInventory] = useState<number>(0);
  useEffect(() => {
    (async () => {
      if (!profile) return;
      try {
        setCollectibles(profile?.collectibles || [])
        setTotalValueOfInventory(profile?.totalValueOfInventory || 0);

      } catch (_e) {
        // console.error(err)
      }
    })()
  }, [profile, profile?.collectibles, profile?.totalValueOfInventory])
  return (
    <Box>
      {/* Total Bail Earned */}
      <Flex
        direction="column"
        justify="center"
        align="center"
        css={{
          mt: "$15",
          minHeight: "300px",
          gap: "$2",
          background: "rgba(100, 117, 185, 0.2)",
          border: "1px solid #6E87C4",
          borderRadius: "15px",
        }}
      >
        <Text css={{ color: "$primary", fontWeight: 600, fontSize: "42px" }}>
          Total Value Of Inventory
        </Text>
        <Text
          css={{
            fontWeight: 600,
            fontSize: "30px",
            "& span": { color: "$primary" },
          }}
        >
          <span>$</span> {totalValueOfInventory}
        </Text>
      </Flex>

      {/* All Collectibles */}
      <Box css={{ mt: "$15" }}>
        <Text css={{ fontWeight: 600 }}>All Collectibles</Text>
        {!!collectibles?.length && collectibles.map((item, i) => (<Box key={i}
          css={{
            mt: "$10",
            background: "rgba(0, 0, 0, 0.2)",

            p: "20px",
            borderRadius: "15px",
          }}
        >
          <Flex justify="between">
            <Flex css={{ gap: "$7" }}>
              <Box
                as="img"
                src={`https://cdn.akamai.steamstatic.com/steam/apps/${item.app_id}/header.jpg`}
                css={{ borderRadius: "5px", maxWidth: "30px" }}
              />
              <Text css={{ fontWeight: 600 }}>
                {item.gameName}
              </Text>
            </Flex>
            <Text
              css={{
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.4)",
                cursor: "pointer",
              }}
            >
              See All
            </Text>
          </Flex>

          <Box
            css={{
              mt: "$5",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "$10",
            }}
          >
            {item.collectibles.map((e, index) => (
              <Box
                key={index}
                css={{
                  borderRadius: "20px",
                  background: `url('https://community.akamai.steamstatic.com/economy/image/${e.iconUrl}')`,
                  position: "relative",
                  minHeight: "320px",
                  backgroundSize: "contain",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",

                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.4) 70.83%, rgba(0, 0, 0, 0.8) 85.42%);",
                  }}
                >
                  <Text css={{ fontSize: "24px", fontWeight: 600 }}>
                    {e.name}
                  </Text>
                  <Text css={{ pb: "$12", color: "#FE5D5D" }}>
                    {e.type}
                  </Text>
                </Box>
              </Box>
            ))}
            {/* {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                css={{
                  borderRadius: "20px",
                  background: `url('/images/games/gun-${index + 1}.png')`,
                  position: "relative",
                  minHeight: "320px",
                  backgroundSize: "contain",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",

                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.4) 70.83%, rgba(0, 0, 0, 0.8) 85.42%);",
                  }}
                >
                  <Text css={{ fontSize: "24px", fontWeight: 600 }}>
                    AWP Dragon Lore
                  </Text>
                  <Text css={{ pb: "$12", color: "#FE5D5D" }}>
                    Extremely Rare
                  </Text>
                </Box>
              </Box>
            ))} */}
          </Box>
        </Box>))}

        {/* second one */}
        {/* <Box
          css={{
            mt: "$10",
            background: "rgba(0, 0, 0, 0.2)",

            p: "20px",
            borderRadius: "15px",
          }}
        >
          <Flex justify="between">
            <Flex css={{ gap: "$7" }}>
              <Box
                as="img"
                src="/images/games/game-logo.png"
                css={{ borderRadius: "5px", maxWidth: "30px" }}
              />
              <Text css={{ fontWeight: 600 }}>
                Counter-Strike: Global Offensive
              </Text>
            </Flex>
            <Text
              css={{
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.4)",
                cursor: "pointer",
              }}
            >
              See All
            </Text>
          </Flex>

          <Box
            css={{
              mt: "$5",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "$10",
            }}
          >
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                css={{
                  borderRadius: "20px",
                  background: `url('/images/games/gun-${index + 1}.png')`,
                  position: "relative",
                  minHeight: "320px",
                  backgroundSize: "contain",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",

                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.4) 70.83%, rgba(0, 0, 0, 0.8) 85.42%);",
                  }}
                >
                  <Text css={{ fontSize: "24px", fontWeight: 600 }}>
                    AWP Dragon Lore
                  </Text>
                  <Text css={{ pb: "$12", color: "#FE5D5D" }}>
                    Extremely Rare
                  </Text>
                </Box>
              </Box>
            ))}
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                css={{
                  borderRadius: "20px",
                  background: `url('/images/games/gun-${index + 1}.png')`,
                  position: "relative",
                  minHeight: "320px",
                  backgroundSize: "contain",
                }}
              >
                <Box
                  css={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    position: "absolute",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",

                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.4) 70.83%, rgba(0, 0, 0, 0.8) 85.42%);",
                  }}
                >
                  <Text css={{ fontSize: "24px", fontWeight: 600 }}>
                    AWP Dragon Lore
                  </Text>
                  <Text css={{ pb: "$12", color: "#FE5D5D" }}>
                    Extremely Rare
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
