import { User } from "@honeycomb-protocol/edge-client";
import { Grid, Image, Text, Tooltip } from "@nextui-org/react";
// import { useGateway } from "@civic/solana-gateway-react";

import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import { SocialLinks } from "./SocialLinks";
// import { useHoneycomb } from "../../hooks";

const Profile = ({
  loading,
  user,
}: {
  loading: Boolean;
  user: User | undefined;
}) => {
  // const { gatewayStatus } = useGateway();
  // const { wallet } = useHoneycomb();

  return (
    <>
      {loading ? (
        <h1 style={{ color: "white" }}>Loading User....</h1>
      ) : !user || user === null ? (
        <h1 style={{ color: "white" }}>No User Found</h1>
      ) : (
        <Flex justify="between" align="start">
          <Flex align="center" css={{ gap: "$15", width: "78%" }}>
            <Box className={"hexagonn"}>
              <Box className={"hexagonn-inner"}>
                <Box className="hexagonn-box">
                  <Box
                    className="hexagonn-img"
                    css={{
                      maxWidth: "100%",
                    }}
                    as="img"
                    src={user?.info?.pfp || "/images/hex.png"}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              css={{
                width: "60%",
              }}
            >
              <Box>
                <Flex align="center">
                  <Text
                    css={{
                      lineHeight: "45px",
                      fontWeight: 700,
                      fontSize: "40px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {user?.info.name || "Unnamed"}
                  </Text>
                  {/* {user &&
                    gatewayStatus &&
                    wallet?.publicKey &&
                    user.wallets.wallets.includes(
                      wallet.publicKey.toString()
                    ) &&
                    gatewayStatus === 9 && (
                      <Tooltip content="Civic Verified">
                        <Image
                          css={{
                            ml: 15,
                            mt: 5,
                            width: 30,
                            height: 30,
                          }}
                          containerCss={{ m: 0 }}
                          src={require("../../assets/images/orangeVerification.png")}
                        />
                      </Tooltip>
                    )} */}
                </Flex>
                <Text
                  css={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: "19px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    mt: "$2",
                  }}
                >
                  {user?.info.username || user?.wallets.wallets[0].toString()}
                </Text>
              </Box>
              <Text css={{ fontSize: "19px", mb: "$2", fontWeight: 500 }}>
                Bio
              </Text>
              <Text
                css={{
                  fontWeight: 400,
                  fontSize: "17px",
                  lineHeight: "22px",
                  opacity: ".7",
                }}
              >
                {user?.info?.bio || "Edit bio"}
              </Text>
            </Box>
          </Flex>

          <Box
            css={{
              mt: "$10",
              width: "20%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SocialLinks />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Profile;
