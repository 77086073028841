import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as web3 from "@solana/web3.js";
import { ICollectible } from "../../components/Steam/Collectibles";
import { IFriendList } from "../../components/Steam/FriendList";
import { ISteamGame } from "../../components/Steam/Overview";
import { Profile } from "@honeycomb-protocol/edge-client";

export type SteamProfile = Profile & {
  walletAddress?: web3.PublicKey;
  steamId?: string;
  steamUsername?: string;
  steamLevel?: number;
  steamImage?: string;
  steamCountry?: string;
  collectibles?: ICollectible[];
  friends?: IFriendList[];
  games?: ISteamGame[];
  averagePlayTimePerGame?: string;
  numberOfGamesPlayed?: number;
  totalPlayTime?: string;
  totalValueOfInventory?: number;
};
export interface SteamState {
  profiles: {
    [key: string]: SteamProfile;
  };
  searchedProfiles: {
    [key: string]: SteamProfile;
  };
  apiCalled: boolean;
  searchedApiCalled: boolean;
  totalProfilesFromApi: {
    address: string;
    identity: string;
    steamId: string;
    steamLevel: number;
    steamUsername: string;
    userAddress: string;
    steamImage: string;
    steamCountry: string;
  }[];
  searchedTotalProfilesFromApi: {
    address: string;
    identity: string;
    steamId: string;
    steamLevel: number;
    steamUsername: string;
    userAddress: string;
    steamImage: string;
    steamCountry: string;
  }[];
  progress: { [key: string]: boolean };
  isFetching: boolean;
  isFetchingSearched: boolean;
}

const initialState: SteamState = {
  profiles: {},
  searchedProfiles: {},
  totalProfilesFromApi: [],
  searchedTotalProfilesFromApi: [],
  apiCalled: false,
  searchedApiCalled: false,
  progress: {},
  isFetching: false,
  isFetchingSearched: false,
};

export const slice = createSlice({
  name: "steam",
  initialState,
  reducers: {
    setProfiles: (
      state,
      action: PayloadAction<{ [key: string]: SteamProfile }>
    ) => {
      state.profiles = { ...state.profiles, ...action.payload };
    },
    clearedData: (state) => {
      state.apiCalled = false;
      state.searchedApiCalled = false;
      state.profiles = {};
      state.searchedProfiles = {};
      state.totalProfilesFromApi = [];
      state.searchedTotalProfilesFromApi = [];
      state.progress = {};
    },
    setSearchedProfiles: (
      state,
      action: PayloadAction<{ [key: string]: SteamProfile }>
    ) => {
      state.searchedProfiles = { ...state.profiles, ...action.payload };
    },
    setApiProfiles: (
      state,
      action: PayloadAction<
        {
          address: string;
          identity: string;
          steamId: string;
          steamLevel: number;
          steamUsername: string;
          userAddress: string;
          steamImage: string;
          steamCountry: string;
        }[]
      >
    ) => {
      state.totalProfilesFromApi = action.payload;
    },
    setSearchedApiProfiles: (
      state,
      action: PayloadAction<
        {
          address: string;
          identity: string;
          steamId: string;
          steamLevel: number;
          steamUsername: string;
          userAddress: string;
          steamImage: string;
          steamCountry: string;
        }[]
      >
    ) => {
      state.searchedTotalProfilesFromApi = action.payload;
    },
    setApiCalled: (state, action: PayloadAction<boolean>) => {
      state.apiCalled = action.payload;
    },
    setSearchedApiCalled: (state, action: PayloadAction<boolean>) => {
      state.searchedApiCalled = action.payload;
    },
    setProgress: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.progress = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setIsFetchingSearched: (state, action: PayloadAction<boolean>) => {
      state.isFetchingSearched = action.payload;
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
