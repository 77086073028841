import React from "react";
import { useSelector } from "react-redux";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Profile, Project, User } from "@honeycomb-protocol/edge-client";

import { RootState, useAppDispatch } from "../store";
import { honeycomb as honeycombActions } from "../store/actions";
import { SocialInfo } from "@honeycomb-protocol/hive-control";

export function useHoneycomb() {
  const dispatch = useAppDispatch();
  const { user, edgeClient, wallet, profile } = useSelector(
    (state: RootState) => state.honeycomb
  );
  const {
    fetchUser: fetchingUser,
    fetchProfile: fetchingProfile,
    createUser: creatingUser,
    createProfile: creatingProfile,
    honeycomb: honeycombLoading,
    fetchUserNfts: fetchingNfts,
  } = useSelector((state: RootState) => state.honeycomb.loaders);
  const { authToken, authLoader } = useSelector(
    (state: RootState) => state.auth
  );

  const fetchProfile = React.useCallback(async () => {
    const profile = await dispatch(honeycombActions.fetchProfile()).then(
      (x) => x.payload as Profile
    );
    return profile;
  }, []);

  const setWallet = React.useCallback(async (wallet: WalletContextState) => {
    // console.log("Set Wallet");
    // alert('Auth');

    const walletContext = await dispatch(
      honeycombActions.setWallet(wallet)
    ).then((x) => x.payload as WalletContextState);
    // console.log("Wallet", walletContext);
    return walletContext;
  }, []);

  const createUserAndProfile = React.useCallback(
    async (args: {
      username: string;
      name: string;
      bio: string;
      pfp: string | File;
    }) => {
      const identity = await dispatch(
        honeycombActions.createUserAndProfile(args)
      ).then((x) => x.payload as User);
      return identity;
    },
    []
  );

  const createUser = React.useCallback(
    async (args: {
      username: string;
      bio: string;
      pfp: string | File;
      name: string;
    }) => {
      const identity = await dispatch(honeycombActions.createUser(args)).then(
        (x) => x.payload as User
      );
      return identity;
    },
    []
  );

  const createProfile = React.useCallback(
    async (args: { name: string; bio: string; pfp: string | File }) => {
      const identity = await dispatch(
        honeycombActions.createProfile(args)
      ).then((x) => x.payload as Profile);
      return identity;
    },
    []
  );

  const updateProfile = React.useCallback(
    async (args: { name?: string; bio?: string; pfp?: string | File }) => {
      const identity = await dispatch(honeycombActions.updateProfile(args));
      return identity;
    },
    []
  );

  const updateUser = React.useCallback(
    async (args: {
      name?: string;
      bio?: string;
      pfp?: string | File;
      socialInfo?: SocialInfo;
      addWallet?: string;
      removeWallet?: string;
    }) => {
      const user = await dispatch(honeycombActions.updateUser(args));
      return user;
    },
    []
  );

  const loadIdentityDeps = React.useCallback(
    async (wallet: WalletContextState) => {
      const status = await dispatch(
        honeycombActions.loadIdentityDeps(wallet)
      ).then((x) => x.payload as boolean);
      return status;
    },
    []
  );

  const fetchSearchedUser = React.useCallback(
    ({ name, wallet }: { name?: string; wallet?: WalletContextState }) => {
      if (name) {
        return dispatch(honeycombActions.fetchSearchedUser({ name }));
      } else {
        return dispatch(honeycombActions.fetchSearchedUser({ wallet }));
      }
    },
    []
  );

  const isUsernameAvailable = React.useCallback(async (username: string) => {
    const user = await fetchSearchedUser({ name: username }).then(
      (x) => x.payload as User[]
    );
    return user.length > 0 ? false : true;
  }, []);

  const addWallet = React.useCallback(async () => {
    return (await dispatch(honeycombActions.addWallet())).payload as User;
  }, []);

  const fetchUserProfiles = React.useCallback(async (userId: number) => {
    return dispatch(
      honeycombActions.fetchUserProfiles({ userId: userId })
    ).then((x) => x.payload as Profile[]);
  }, []);

  const getUserProjects = React.useCallback(
    async (projectAddresses: string[]) => {
      return dispatch(
        honeycombActions.getUserProjects({ projectAddresses })
      ).then((x) => x.payload as Project[]);
    },
    []
  );

  return {
    user,
    profile,
    creatingUser,
    fetchingUser,
    creatingProfile,
    fetchingProfile,
    honeycombLoading,
    fetchingNfts,
    loadIdentityDeps,
    createUser,
    createProfile,
    updateProfile,
    updateUser,
    setWallet,
    fetchProfile,
    edgeClient,
    wallet,
    createUserAndProfile,
    authLoader,
    authToken,
    fetchSearchedUser,
    isUsernameAvailable,
    addWallet,
    fetchUserProfiles,
    getUserProjects,
  };
}
