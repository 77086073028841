
import { Personal, Socials, Verification, Wallets } from "../components";
import Steam from "../integrations/Steam";
import { RouteType } from "./appRoutes";

const editProfileRoute: RouteType[] = [
  {
    path: '/edit/personal',
    state: 'personal',
    element: <Personal />,
  },
  {
    path: '/edit/wallets',
    state: 'personal',
    element: <Wallets />,
  },
  {
    path: '/edit/socials',
    state: 'socials',
    element: <Socials />,
  },
  {
    path: '/edit/verification',
    state: 'verification',
    element: <Verification />,
  },
  {
    path: '/edit/socials/steam/callback',
    state: 'steam',
    element: <Steam />,
  },

];

export default editProfileRoute;
