import { Box } from "../ui/Box";
import { Collectibles } from "./Collectibles";
import { Overview } from "./Overview";
import { Stats } from "./Stats";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FriendList } from "./FriendList";
import { useHoneycomb, useSteam } from "../../hooks";
import { SteamProfile } from "../../contexts/SteamProvider";

export const tabs = ["Overview", "Stats", "Collectibles", "FriendList"];

const Steam = () => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState("Overview");
  const { profiles, searchedProfiles } = useSteam();
  const { setSearchingUser, user, searchingUser } = useHoneycomb();
  const location = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState<SteamProfile | undefined>(
    undefined
  );
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  // React.useEffect(() => {
  //   if (params.username !== user?.username) {
  //     setSearchingUser(params.username || false);
  //   } else {
  //     setSearchingUser(false);
  //   }
  // }, [params, setSearchingUser, user?.username]);

  React.useEffect(() => {
    try {
      if (!!searchingUser) {
        if (params.steamId && searchedProfiles[params.steamId]) {
          params.steamId && setProfile(searchedProfiles[params.steamId]);
        } else navigate(`/${params.username}`);
      } else {
        if (params.steamId && profiles[params.steamId]) {
          params.steamId && setProfile(profiles[params.steamId]);
        } else navigate(`/${params.username}`);
      }
    } catch {}
  }, [
    setProfile,
    profile,
    params.steamId,
    profiles,
    location,
    searchedProfiles,
    searchingUser,
    params.username,
    navigate,
  ]);

  const render: any = () => {
    switch (activeTab.toLowerCase()) {
      case tabs[0].toLowerCase():
        return <Overview profile={profile} />;
      case tabs[1].toLowerCase():
        return <Stats profile={profile} />;
      case tabs[2].toLowerCase():
        return <Collectibles profile={profile} />;
      case tabs[3].toLowerCase():
        return <FriendList profile={profile} />;

      default:
        return <h1>No project match</h1>;
    }
  };
  return <Box>{render()}</Box>;
};

export default Steam;
