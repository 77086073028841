import React from "react";
import { Button, Dropdown, Image } from "@nextui-org/react";
import * as web3 from "@solana/web3.js";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";

import { Box } from "../ui/Box";
import { Flex } from "../ui/Flex";
import { useHoneycomb } from "../../hooks";
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet-icon.svg";
import AutoComplete from "../ConnectWallet/AutoCompelete";
import { auth } from "../../store/actions";

function parsePublicKeyString(publicKey: web3.PublicKey) {
  const str = publicKey.toString();
  return str.slice(0, 4) + "..." + str.slice(-4);
}

function Navbar() {
  const dispatch = useDispatch();
  const wallet = useWallet();
  const walletModal = useWalletModal();
  const { authToken, user } = useHoneycomb();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const onAction = React.useCallback(
    async (key: React.Key) => {
      switch (key) {
        case "wallet":
          navigate("/edit/wallets");
          break;
        case "profile":
          navigate("/edit/personal");
          break;
        case "logout":
          await dispatch(auth.logout() as any);
          params.delete("username");
          setParams({ username: "" });
          navigate("/");
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const navigating = React.useCallback(() => {
    if (user?.info.username) {
      navigate(`/?username=${user?.info.username}`);
    } else {
      navigate("/");
    }
  }, [navigate, user?.info.username]);

  return (
    <Box>
      <Flex align="center" css={{ height: "120px" }}>
        {/* {location.pathname === "/" ? (
          <Box css={{ flexGrow: 1 }}></Box>
        ) : ( */}
        <Box onClick={navigating} css={{ marginRight: "auto" }}>
          <Image
            css={{ position: "relative", zIndex: 999, cursor: "pointer" }}
            width={200}
            height={200}
            src="/images/honeycomb-logo.png"
            alt="Default Image"
            objectFit="contain"
          />
        </Box>
        {/* )} */}

        {!wallet.connected ? (
          <Button
            css={{
              padding: "32px 40px",
              background: "#5062AB",
              borderRadius: "15px",
              fontWeight: "bold",
              fontSize: "18px",
              "& svg": {
                mr: "$5",
                ml: "$8",
                height: "25px",
                width: "25px",
              },
              "& .nextui-button-text": {
                pl: "$12",
              },
            }}
            icon={<WalletIcon />}
            onPress={() => {
              if (authToken) {
                onAction("logout");
              }
              walletModal.setVisible(true);
            }}
          >
            Connect Wallet
          </Button>
        ) : (
          <Dropdown>
            <Dropdown.Button
              css={{
                padding: "32px 10px",
                background: "#5062AB",
                borderRadius: "15px",
                fontWeight: "bold",
                fontSize: "18px",
                "& svg": {
                  mr: "$5",
                  ml: "$8",
                  height: "25px",
                  width: "25px",
                },
                "& .nextui-button-text": {
                  pl: "$12",
                },
              }}
              icon={<WalletIcon />}
            >
              {wallet.publicKey
                ? parsePublicKeyString(wallet.publicKey)
                : "Connect Wallet"}
            </Dropdown.Button>
            <Dropdown.Menu onAction={onAction} aria-label="Static Actions">
              <Dropdown.Item key="wallet">Add Wallet</Dropdown.Item>
              <Dropdown.Item key="profile">Edit Profile</Dropdown.Item>
              <Dropdown.Item key="logout">Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {/* {!isEthereumConnected ? (
          <Button
            css={{
              padding: "32px 40px",
              background: "#5062AB",
              borderRadius: "15px",
              fontWeight: "bold",
              fontSize: "18px",
              "& svg": {
                mr: "$5",
                ml: "$8",
                height: "25px",
                width: "25px",
              },
              "& .nextui-button-text": {
                pl: "$12",
              },
            }}
            icon={<WalletIcon />}
            onPress={() => connectEthereum()}
          >
            Connect Ethereum Wallet
          </Button>
        ) : (
          <Button
            css={{
              padding: "32px 40px",
              background: "#5062AB",
              borderRadius: "15px",
              fontWeight: "bold",
              fontSize: "18px",
              "& svg": {
                mr: "$5",
                ml: "$8",
                height: "25px",
                width: "25px",
              },
              "& .nextui-button-text": {
                pl: "$12",
              },
            }}
            icon={<WalletIcon />}
            onPress={() => disconnedEthereum()}
          >
            {ethereumAddress
              ? `${ethereumAddress.slice(0, 5)}...`
              : "Connect Ethereum Wallet"}
          </Button>
        )} */}
      </Flex>
      <Box
        css={{
          mt: "$15",
          maxW: "55%",
          mx: "auto",
          background: "#121212",
          borderRadius: "70px",
          position: "relative",
          // padding: "20px",
        }}
      >
        <AutoComplete home />
      </Box>
      {/* <Flex
        as="form"
        // onSubmit={handleSubmit(onSubmit)}
        css={{
          mt: "$15",
          maxW: "55%",
          mx: "auto",
          background: "#121212",
          borderRadius: "70px",
          position: "relative",
          padding: "20px",
        }}
      >
        <Input
          aria-label="search user"
          // {...register('user', { required: true })}
          css={{
            width: "100%",
            borderRadius: "60px",
            overflow: "hidden",

            input: {
              py: "15px",
              px: "$5",
            },
            "&>div": { height: "inherit !important" },
          }}
          placeholder="Looking for a friend’s gaming profile?"
        />
        <Button
          type="submit"
          css={{
            right: "20px",
            position: "absolute",
            background: "#2F2079",
            borderRadius: "60px",
            fontSize: "20px",
            fontWeight: "bold",
            py: "30px",
            pr: 0,
          }}
          iconLeftCss={{
            ml: "$7",
            svg: { height: "25px", width: "25px" },
          }}
          icon={<SearchIcon />}
        >
          Search
        </Button>
      </Flex> */}
    </Box>
  );
}

export default Navbar;
