import { Grid, Text } from "@nextui-org/react";
import { Box } from "../../ui/Box";
import { Flex } from "../../ui/Flex";
import moment from "moment";
import { SteamProfile } from "../../../contexts/SteamProvider";

export const Stats = ({ profile }: { profile: SteamProfile | undefined }) => {


  return (
    <Box>
      {/* top stats */}
      <Flex justify="between" align="center" css={{ gap: "$10", mt: "$15" }}>
        <Flex
          // justify='between'
          align="center"
          direction="column"
          css={{
            gap: "$5",
            background: "rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            w: "100%",
            px: "$15",
            py: "$20",
            minHeight: "250px",
            textAlign: "center",
          }}
        >
          <Text css={{ color: "#95A7ED", fontSize: "20px", fontWeight: 500 }}>
            {profile?.totalPlayTime || 0}
          </Text>
          <Text css={{ fontWeight: 600, fontSize: "21px", lineHeight: 1 }}>
            Total Playtime
          </Text>
        </Flex>

        <Flex
          // justify='between'
          align="center"
          direction="column"
          css={{
            gap: "$5",
            background: "rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            w: "100%",
            px: "$15",
            py: "$20",
            minHeight: "250px",
            textAlign: "center",
          }}
        >
          <Text css={{ color: "#95A7ED", fontSize: "22px", fontWeight: 500 }}>
            {profile?.numberOfGamesPlayed || 0}
          </Text>
          <Text css={{ fontWeight: 600, fontSize: "22px", lineHeight: 1 }}>
            Number Of Games Played
          </Text>
        </Flex>
        <Flex
          // justify='between'
          align="center"
          direction="column"
          css={{
            gap: "$5",
            background: "rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            w: "100%",
            px: "$15",
            py: "$20",
            minHeight: "250px",
            textAlign: "center",
          }}
        >
          <Text css={{ color: "#95A7ED", fontSize: "22px", fontWeight: 500 }}>
            {profile?.averagePlayTimePerGame?.includes("NaN") ? 0 : profile?.averagePlayTimePerGame}
          </Text>
          <Text css={{ fontWeight: 600, fontSize: "22px", lineHeight: 1 }}>
            {" "}
            Average Playtime Per Game
          </Text>
        </Flex>
      </Flex>

      {/* Your In-Game Statistics */}
      <Box css={{ mt: "$15" }}>
        <Text css={{ fontWeight: 600, fontSize: "22px", my: "$5" }}>
          Your In-Game Statistics
        </Text>

        {!!profile?.games?.length && profile?.games.slice(0, 2).map((item, index) => (<Box
          key={index}
          css={{
            mt: "$15",
            background: "rgba(0, 0, 0, 0.15)",
            borderRadius: "15px",
            w: "100%",
            px: "$10",
            py: "$10",
          }}
        >
          <Grid.Container gap={3}>
            <Grid lg={5}>
              <Box
                as="img"
                src={`https://cdn.akamai.steamstatic.com/steam/apps/${item.app_id}/header.jpg`}
                css={{ borderRadius: "20px", width: "100%" }}
              />
            </Grid>
            <Grid lg={7}>
              <Box css={{ width: "100%" }}>
                <Text
                  css={{
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "34px",
                    mb: "$10",
                  }}
                >
                  {
                    item.gameName
                  }
                </Text>
                <Flex css={{ gap: "$12" }}>
                  <Box
                    css={{
                      background: "rgba(0, 0, 0, 0.2)",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      p: "$15",
                    }}
                  >
                    <Text css={{ fontSize: "20px", fontWeight: 500 }}>
                      Total Playtime
                    </Text>
                    <Text
                      css={{ fontSize: "20px", fontWeight: 400, opacity: ".3" }}
                    >
                      {(item.playtimeForever / 60).toFixed()} hours
                    </Text>
                  </Box>
                  <Box
                    css={{
                      background: "rgba(0, 0, 0, 0.2)",
                      width: "100%",
                      textAlign: "center",
                      borderRadius: "15px",
                      p: "$15",
                    }}
                  >
                    <Text css={{ fontSize: "20px", fontWeight: 500 }}>
                      Last Played
                    </Text>
                    <Text
                      css={{ fontSize: "20px", fontWeight: 400, opacity: ".3" }}
                    >
                      {moment(item.rTimeLastPlayed).format("DD MMMM")}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Grid>
          </Grid.Container>

          {!!item.stats.length && <Flex css={{ mt: "$10", gap: "$10", width: "100%" }}>
            <Flex
              direction="column"
              align={"center"}
              css={{
                width: "100%",
                background: "rgba(100, 117, 185, 0.1)",
                border: "2px solid #6475B9",
                gap: "$3",
                borderRadius: "15px",
                py: "$10",
              }}
            >
              <Text css={{ fontWeight: 600, fontSize: "22px" }}>Win Ratio</Text>

              <Text css={{ fontSize: "18px" }}>
                {((item.stats.find((item) => item.name === "total_matches_won")?.value || 0) / (item.stats.find((item) => item.name === "total_matches_played")?.value || 0) * 100).toFixed(2) || 0}%
              </Text>
            </Flex>
            <Flex
              direction="column"
              align={"center"}
              css={{
                width: "100%",
                background: "rgba(100, 117, 185, 0.1)",
                border: "2px solid #6475B9",
                gap: "$3",
                borderRadius: "15px",
                py: "$10",
              }}
            >
              <Text css={{ fontWeight: 600, fontSize: "22px" }}>K/D Ratio</Text>

              <Text css={{ fontSize: "18px" }}>{
                ((item.stats.find((item) => item.name === "total_kills")?.value || 0) / (item.stats.find((item) => item.name === "total_deaths")?.value || 0)).toFixed(2) || 0
              }</Text>
            </Flex>
            <Flex
              direction="column"
              align={"center"}
              css={{
                width: "100%",
                background: "rgba(100, 117, 185, 0.1)",
                border: "2px solid #6475B9",
                gap: "$3",
                borderRadius: "15px",
                py: "$10",
              }}
            >
              <Text css={{ fontWeight: 600, fontSize: "22px" }}>Matches</Text>

              <Text css={{ fontSize: "18px" }}>{item.stats.find((item) => item.name === "total_matches_played")?.value || 0}</Text>
            </Flex>
          </Flex>}
        </Box>
        ))}

      </Box>
    </Box>
  );
};
