import { Button, Grid, Image, Text } from "@nextui-org/react";
import { BsFillTrash3Fill } from "react-icons/bs";
import { Box } from "../../../ui/Box";
import { useMemo } from "react";
import { PublicKey } from "@solana/web3.js";

export type IAccountType = "solana" | "ethereum";
export type IWalletType = "phantom" | "metamask";
export type IPlatformType = "solpatrol" | "xbox" | "playstation" | "steam";

interface WalletItemProps {
  iconName?: IWalletType;
  eBtnIcon?: IAccountType | IPlatformType;
  key?: number;
  address?: PublicKey | string;
  onDelete?: () => void;
}

export const iconUrl = (
  iconName: IAccountType | IWalletType | IPlatformType
) => {
  switch (iconName) {
    case "phantom":
      return require("../../../../assets/images/phantom.png");
    case "metamask":
      return require("../../../../assets/images/metamask.png");
    case "steam":
      return require("../../../../assets/images/steam.png");
    case "solana":
      return require("../../../../assets/images/solana.png");
    case "ethereum":
      return require("../../../../assets/images/ethereum.png");
    case "xbox":
      return require("../../../../assets/images/xbox.png");
    case "playstation":
      return require("../../../../assets/images/playstation.png");
    case "solpatrol":
      return require("../../../../assets/images/solpatrol.png");
    default:
      return require("../../../../assets/images/phantom.png");
  }
};
const WalletItem = ({
  iconName,
  eBtnIcon,
  key,
  address,
  onDelete,
}: WalletItemProps) => {
  const shortName = useMemo(() => {
    return typeof address == "string"
      ? address
      : address &&
      address?.toString().slice(0, 5) +
      "..." +
      address?.toString().slice(-5);
  }, [address]);
  return (
    <Grid.Container key={key} gap={2}>
      <Grid xs={12} sm={eBtnIcon ? 5 : 6}>
        <Box
          css={{
            background: "#00000033",
            p: 15,
            width: "100%",
            borderRadius: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Text css={{ color: "white", m: 0, width: '100%', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: 'nowrap', }} h5>
            {shortName}
          </Text>
          {iconName && (
            <Image
              css={{
                width: 35,
                height: 35,
                p: 0,
                m: 0,
              }}
              src={iconUrl(iconName)}
              containerCss={{
                m: 0,
              }}
            />
          )}
        </Box>
      </Grid>
      {eBtnIcon && (
        <Grid css={{ display: "flex", alignItems: "center" }} xs={7} sm={4}>
          <Box
            css={{
              width: "100%",
              height: "58px",
              background: "#4A3D8B",
              p: 10,
              borderRadius: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              css={{
                height: 25,
                width: 25,
              }}
              containerCss={{ m: 0 }}
              src={iconUrl(eBtnIcon)}
            />
            <Text h4 css={{ textTransform: "capitalize", m: 0, ml: 10 }}>
              {eBtnIcon}
            </Text>
          </Box>
        </Grid>
      )}
      {onDelete && (
        <Grid
          css={{ display: "flex", alignItems: "center" }}
          xs={5}
          sm={eBtnIcon ? 2 : 3}
          onClick={onDelete}
        >
          <Button
            icon={<BsFillTrash3Fill />}
            css={{ background: "#843838", height: "58px", fontSize: 18 }}
          >
            Delete
          </Button>
        </Grid>
      )}
    </Grid.Container>
  );
};

export default WalletItem;
