import { ReactNode } from "react";
import { Outlet } from "react-router-dom";

import { ReactComponent as SolanaLogo } from "../assets/icons/solana-logo.svg";
import { Solpatrol, Steam } from "../components";
import EmptyLayout from "../components/EmptyLayout";
import { Box } from "../components/ui/Box";
import NewTab from "../components/NewTab";

export interface IRT {
  element?: ReactNode;
  state: string;
  index?: boolean;
  path?: string;

  redirect?: boolean;
  sidebarProps?: {
    displayText: string;
    icon?: ReactNode;
    img?: string;
  };
}
export interface IChild {
  child?: RouteType[];
}

export interface RouteType extends IRT, IChild {}

const appRoutes: RouteType[] = [
  {
    path: "/",
    element: <Outlet />,
    state: "solana",
    sidebarProps: {
      displayText: "Solana",
      icon: <SolanaLogo />,
    },
    child: [
      {
        path: "/:username",
        element: <EmptyLayout />,
        state: "solana.solpatrol",
      },
    ],
  },
  {
    path: "/:username/solpatrol/:identity",
    element: (
      <Box>
        <NewTab module="solpatrol" />
        <Solpatrol />
      </Box>
    ),
    state: "solana.solpatrol",
  },

  {
    path: "/:username/steam/:steamId",
    element: (
      <Box>
        <NewTab module="steam" />
        <Steam />
      </Box>
    ),
    state: "steam",
  },

  {
    path: "/",
    element: <Outlet />,
    state: "eth",
    sidebarProps: {
      displayText: "Ethereum",
      img: "/images/eth-logo.png",
    },
    child: [
      {
        path: "/eth/sol-patrol",
        element: <>Home</>,
        state: "eth.solpatrol",
        sidebarProps: {
          displayText: "Sol Patrol",
        },
      },
    ],
  },

  {
    path: "/",
    element: <Outlet />,
    state: "stm",
    sidebarProps: {
      displayText: "Steam",
      img: "/images/steam-logo.png",
    },
    child: [
      {
        path: "/steam/klep",
        element: <Steam />,
        state: "steam.klep",
        sidebarProps: {
          displayText: "Klep",
          img: "/images/user.png",
        },
      },
    ],
  },

  {
    path: "/",
    element: <Outlet />,
    state: "xbox",
    sidebarProps: {
      displayText: "Xbox",
      img: "/images/xbox-logo.png",
    },
    child: [
      {
        path: "/xbox/user",
        element: <>xbox</>,
        state: "xbox.user",
        sidebarProps: {
          displayText: "Xbox User",
        },
      },
    ],
  },
  {
    path: "/",
    element: <Outlet />,
    state: "playstation",
    sidebarProps: {
      displayText: "Playstation Network",
      img: "/images/playstation-logo.png",
    },
    child: [
      {
        path: "/playstation/user",
        element: <>playstation user</>,
        state: "playstation.user",
        sidebarProps: {
          displayText: "Playstation User",
        },
      },
    ],
  },
];

export default appRoutes;
