import React, { useEffect } from "react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import {
  Button,
  Grid,
  Image,
  Loading,
  Modal,
  Text,
  Tooltip,
} from "@nextui-org/react";
import { useWallet } from "@solana/wallet-adapter-react";
import { User } from "@honeycomb-protocol/edge-client";

import { useHoneycomb } from "../../../hooks";
import { Box } from "../../ui/Box";
import WalletItem from "./components/walletItem";
import { Flex } from "../../ui/Flex";

const Wallets = () => {
  const wallet = useWallet();
  const walletModal = useWalletModal();
  const { user, fetchSearchedUser, addWallet } = useHoneycomb();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isWalletLinkedToAnotherAccount, setIsWalletLinkedToAnotherAccount] =
    React.useState(false);
  const [loader, setLoader] = React.useState<boolean>(false);

  const isWalletLinked = React.useMemo(
    () =>
      !!wallet.publicKey &&
      user?.wallets.wallets.find((x) => x === wallet.publicKey?.toString()),
    [user, wallet]
  );

  useEffect(() => {
    fetchThorughWallet();
  }, [wallet]);

  const fetchThorughWallet = React.useCallback(async () => {
    if (!!wallet.publicKey) {
      setLoader(true);
      const users = (await fetchSearchedUser({ wallet })).payload as User[];

      setIsWalletLinkedToAnotherAccount(!!users.length);
      setLoader(false);
    }
  }, [wallet]);

  return (
    <Box css={{ p: 40, borderRadius: 20, width: "100%" }}>
      <Modal
        closeButton
        // fullScreen
        width="600px"
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <Modal.Header>
          <Text id="modal-title" className="font-bold">
            Add Wallet
          </Text>
        </Modal.Header>
        <Modal.Body css={{ textAlign: "center" }}>
          <Text>
            To add a new wallet, simply change wallets and we will auto-detect
            it
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button color={"secondary"} onClick={() => setIsOpen(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Box css={{ display: "flex", alignItems: "center", mb: "15px" }}>
        <Text h3 css={{ m: 0 }}>
          Wallets
        </Text>
        <Box css={{ display: "flex", alignItems: "center", ml: "10px" }}>
          <Image
            height={20}
            width={20}
            containerCss={{ m: 0 }}
            src={require("../../../assets/images/solana.png")}
          />
          <Text css={{ m: 0, ml: 10 }} h4>
            Solana
          </Text>
        </Box>
      </Box>
      {user?.wallets.wallets.map((address) => (
        <WalletItem address={address} />
      ))}
      <Box css={{ mt: 20, width: "100%" }}>
        <Grid.Container gap={1} direction={"column"}>
          <Text h4>Add Wallet</Text>
          <Flex
            justify="between"
            align="center"
            css={{
              position: "relative",
              zIndex: 2,
              background: "rgba(100, 117, 185, 0.15)",
              border: "1px solid #6475B9",
              borderRadius: "15px",
              py: "$10",
              px: "$10",
            }}
          >
            <Flex direction="column" align="start" justify={"center"}>
              {wallet.publicKey && (
                <Tooltip content={"Click to copy"}>
                  <Text
                    css={{ m: 0, cursor: "pointer" }}
                    h5
                    onClick={() => {
                      navigator.clipboard.writeText(
                        wallet.publicKey?.toString() || ""
                      );
                    }}
                  >
                    {`Connected wallet: ${wallet.publicKey
                      ?.toString()
                      .slice(0, 4)}...${wallet.publicKey
                      ?.toString()
                      .slice(-4)}`}
                  </Text>
                </Tooltip>
              )}

              <Text
                css={{
                  fontWeight: 400,
                  width: "100%",
                  m: 0,
                  p: 0,
                  color: "Orange",
                  lineHeight: "1.2",
                }}
              >
                {isWalletLinked
                  ? `This wallet is already linked to your account.`
                  : wallet.publicKey
                  ? isWalletLinkedToAnotherAccount
                    ? `Your current wallet is already linked to another account, please connect a new wallet to link to this account.`
                    : `Please sign the transaction to prove the ownership of the wallet.`
                  : `Connect a new wallet to link to this account.`}
              </Text>
            </Flex>

            <Button
              css={{
                background: "#2F2079",
                borderRadius: "60px",
                fontWeight: "bold",
              }}
              onPress={() => {
                if (wallet.publicKey) {
                  if (!isWalletLinked && !isWalletLinkedToAnotherAccount) {
                    addWallet();
                  } else {
                    setIsOpen(true);
                  }
                  // addWallet();
                } else walletModal.setVisible(true);
              }}
            >
              {/* adding loader here when loader is true */}

              {loader ? (
                <Loading size="xs" />
              ) : !wallet.publicKey ? (
                "Connect"
              ) : (
                "Add Wallet"
              )}
            </Button>
          </Flex>
          {/* <Grid.Container css={{ marginTop: 40, padding: 10 }} xs={12}>
            <Grid xs={6}>
              <Button type="reset" css={{ background: "#AF5858" }}>
                Cancel
              </Button>
            </Grid>
            <Grid xs={6} justify="flex-end">
              <Button type="submit" css={{ background: "#6475B9" }}>
                Save Changes
              </Button>
            </Grid>
          </Grid.Container> */}
        </Grid.Container>
      </Box>
    </Box>
  );
};

export default Wallets;
